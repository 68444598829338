import axios, { AxiosInstance } from 'axios';
import { DEPLOYMENT_STATUS_API_ENDPOINT } from '../config/env';
import { RequesterOptions } from 'context/requesters/baseRequester';

export type DeploymentStatusConfig = {
  isDeploymentOngoing: boolean;
};

class DeploymentStatusService {
  protected axios: AxiosInstance;

  constructor({ apiToken }: Omit<RequesterOptions, 'baseURL'>) {
    this.axios = axios.create({
      baseURL: '/',
      headers: {
        authorization: `Bearer ${apiToken}`,
        'content-type': 'application/form-data',
      },
    });
  }

  async getDeploymentStatus(): Promise<DeploymentStatusConfig> {
    const res = await this.axios.get(`${DEPLOYMENT_STATUS_API_ENDPOINT}`, {});

    return res.data as DeploymentStatusConfig;
  }
}

export default DeploymentStatusService;
