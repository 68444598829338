export enum InputValidation {
  NONE = 'none',
  PHONE = 'phone',
  NUMBER = 'number',
  NAME = 'name',
  EMAIL = 'email',
  SYMBOL = 'symbol',
  WEBSITE = 'website',
  LONG_TEXT_WEBSITE = 'long_text_website',
  SYMBOL_WEBSITE = 'symbol_website',
}
