import Pagination from 'react-bootstrap/Pagination';
import cx from 'classnames';
import styles from './pagination-bar.module.scss';
import { useBreakpoints, useTranslation } from 'hooks';
import { AccessibilityLive, Button, Icon, Text } from 'components';
import { Colors } from 'types/color.type';

const PaginationBar = ({
  activePage,
  setActivePage,
  totalItems,
  pageSize,
  maxPages,
  useCustomStyling = false,
}: PaginationBarProps) => {
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation();

  const totalPages = Math.ceil(totalItems / pageSize);
  const testStart = Math.max(1, activePage - Math.floor(maxPages / 2));
  const end = Math.min(testStart + (maxPages - 1), totalPages);
  const start = Math.max(1, end - (maxPages - 1));

  if (useCustomStyling) {
    const displayedPages = Array.from({ length: end - start + 1 }, (_, i) => start + i);

    return (
      <>
        <nav
          role="navigation"
          aria-label={t('pagination.navLabel')}
          className={cx(styles.pagination, styles.custom)}
        >
          <ul className={cx(styles.pageList, { [styles.pageListMobile]: isMobile })}>
            <li className={cx(styles.pageListItem, { [styles.pageListItemMobile]: isMobile })}>
              <Button
                onClick={() => setActivePage(activePage - 1)}
                disabled={activePage === 1}
                label={t('pagination.prev')}
                className={cx(styles.pageButton)}
              >
                <Icon
                  name={activePage === 1 ? 'caret' : 'caretBlue'}
                  alt=""
                  width={10}
                  height={25}
                  className={styles.caretLeft}
                />
              </Button>
            </li>
            {displayedPages.map((n) => (
              <li
                key={n}
                className={cx(styles.pageListItem, { [styles.pageListItemMobile]: isMobile })}
              >
                <Button
                  className={cx(styles.pageLink, {
                    [styles.active]: n === activePage,
                  })}
                  onClick={() => setActivePage(n)}
                  ariaCurrent={n === activePage ? 'page' : undefined}
                  label={t('pagination.goto', { page: n })}
                  disabled={n === activePage}
                >
                  <Text
                    tag="span"
                    className={styles.linkText}
                    color={n === activePage ? Colors.CFDarkGrey : Colors.BahamaBlue}
                  >
                    {n}
                    {n === end && totalPages > maxPages && <>{' ...'}</>}
                  </Text>
                </Button>
              </li>
            ))}
            <li className={cx(styles.pageListItem, { [styles.pageListItemMobile]: isMobile })}>
              <Button
                onClick={() => setActivePage(activePage + 1)}
                disabled={activePage === totalPages}
                label={t('pagination.next')}
                className={cx(styles.pageButton)}
              >
                <Icon
                  name={activePage === totalPages ? 'caret' : 'caretBlue'}
                  alt=""
                  width={10}
                  height={25}
                  className={styles.caretRight}
                />
              </Button>
            </li>
          </ul>
        </nav>
        <AccessibilityLive isOn label={t('pagination.currentlyOn', { page: activePage })} />
      </>
    );
  }

  const items = [];

  for (let page = start; page <= end; page++) {
    if (totalPages > 1) {
      items.push(
        <Pagination.Item
          key={page}
          active={page === activePage}
          onClick={() => setActivePage(page)}
        >
          {page === end && end !== totalPages ? `${page}...` : page}
        </Pagination.Item>
      );
    }
  }
  return (
    <div className={styles.pagination}>
      {totalPages > 1 && (
        <Pagination>
          <Pagination.Prev
            onClick={() => setActivePage(activePage - 1)}
            className="page-navigation"
            disabled={activePage === 1}
            aria-disabled={activePage === 1}
          />
          {items}
          <Pagination.Next
            onClick={() => setActivePage(activePage + 1)}
            className="page-navigation"
            disabled={activePage === totalPages}
            aria-disabled={activePage === totalPages}
          />
        </Pagination>
      )}
    </div>
  );
};

interface PaginationBarProps {
  activePage: number;
  setActivePage: (page: number) => void;
  totalItems: number;
  pageSize: number;
  maxPages: number;
  useCustomStyling?: boolean;
}

export default PaginationBar;
