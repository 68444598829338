import { useEffect, useMemo, useCallback, useRef, useState, ChangeEvent } from 'react';
import { debounce } from 'lodash-es';
import styles from './food-provider-sidebar.module.scss';
import {
  CustomButton,
  PaginationBar,
  Shimmer,
  SortFilter,
  TabItem,
  Text,
  TextInput,
} from 'components';
import { useBreakpoints, useTranslation } from 'hooks';
import { useAppContext } from 'context/app-context';
import { PersistentStateRef, useFoodProviderContext } from 'context/food-provider-context';
import { Colors } from 'types/color.type';
import Status from 'constants/Status';
import Lang from 'constants/Locale';
import {
  dateFormat,
  FOOD_PROVIDERS_PER_PAGE,
  getFloorFieldId,
  getIconDimensions,
} from 'helpers/foodProvider';

const FoodProviderSidebar = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const {
    foodProviders,
    foodProviderToCreate,
    setFoodProviderToCreate,

    selectedFoodProvider,
    setSelectedFoodProvider,

    setSelectedOrderOptions,
    setOrderOptionsToCreate,
    setOrderOptionsToDelete,

    setSearchValue,

    setIsEditing,

    setIncompleteOrderOptionCards,

    currentPage,
    setCurrentPage,

    isLoading,

    foodProviderRequester,

    floors,
  } = useFoodProviderContext();

  const { locale } = useAppContext();
  const dimensions = useMemo(() => getIconDimensions(isMobile), [isMobile]);

  const [floorNameToCreate, setFloorNameToCreate] = useState<string>('');
  const persistentStateRef = useRef<PersistentStateRef>(null);

  const [searchInputVal, setSearchInputVal] = useState<string>('');

  const debouncedSetSearchValue = useCallback(
    debounce((value) => setSearchValue(value), 500, { trailing: true }),
    []
  );

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInputVal(e.target.value);
    debouncedSetSearchValue(e.target.value);
  };

  useEffect(() => {
    if (foodProviderToCreate && foodProviderRequester) {
      const floorFieldId = getFloorFieldId(foodProviderToCreate);
      if (floorFieldId && floorFieldId !== persistentStateRef?.current?.floorFieldId) {
        if (!persistentStateRef?.current) {
          persistentStateRef.current = { floorFieldId };
        } else {
          persistentStateRef.current = { ...persistentStateRef.current, floorFieldId };
        }
        foodProviderRequester?.getFloorById(floorFieldId).then((result) => {
          setFloorNameToCreate(result?.name?.[Lang.en]);
        });
      }
    }
  }, [foodProviderRequester, foodProviderToCreate]);

  const initFoodProviderCreate = () => {
    setFoodProviderToCreate({});
    setSelectedFoodProvider({});
    setSelectedOrderOptions([]);
    setOrderOptionsToCreate([]);
    setOrderOptionsToDelete([]);
    setIncompleteOrderOptionCards(1);
    setIsEditing(true);
  };

  return (
    <>
      <div className={styles.titleContainer}>
        <div className={styles.titleContainerText}>
          <Text color={Colors.CFDarkGrey} type="bodyLg">
            {t('food_provider_left_panel.subtitle')}
          </Text>
          <Text color={Colors.MidnightBlue} type="h2">
            {t('food_provider_left_panel.title')}
          </Text>
        </div>
        <CustomButton
          disabled={
            isLoading
            // || // TODO: Fix Create Button Disabled State
            // !availablePropertyOptions ||
            // Object.keys(availablePropertyOptions).length === 0
          }
          variant="cf-text"
          alt=""
          text={t('food_provider_left_panel.create_button')}
          iconName="add"
          iconHeight={dimensions.add.height}
          iconWidth={dimensions.add.width}
          className={styles.titleButton}
          onClick={initFoodProviderCreate}
        />
      </div>
      <div className={styles.searchContainer}>
        <TextInput
          id="search"
          type="text"
          value={searchInputVal}
          label={t('food_provider_left_panel.search_placeholder')}
          leftIcon
          icon={{
            name: 'searchBlack',
            alt: '',
            height: dimensions.searchBlack.height,
            width: dimensions.searchBlack.width,
          }}
          onChange={handleSearchInput}
          className={styles.searchInput}
          placeholder={t('food_provider_left_panel.search_placeholder')}
        />
        <Text color={Colors.CFDarkGrey} type="bodySm" className={styles.searchDescription}>
          {t('food_provider_left_panel.search_help_text')}
        </Text>
      </div>
      <div className={styles.tabsContainer}>
        <SortFilter />

        <div className={styles.tabItemContainer}>
          {foodProviderToCreate && (
            <div key={'food-provider-to-create'}>
              <TabItem
                onSelect={() => console.log('Food Provider to create, onClick TBD')}
                unitName={foodProviderToCreate?.unit?.[Lang.en]?.fields?.WebStoreName?.[locale]}
                propertyName={
                  foodProviderToCreate?.property?.[Lang.en]?.fields?.WebPropertyName?.[Lang.en]
                }
                floorName={floorNameToCreate}
                status={Status.Draft}
                lastUpdated={dateFormat.format(new Date())}
                isSelected={!selectedFoodProvider?.sys?.id && !!foodProviderToCreate}
              />
            </div>
          )}
          {isLoading && <Shimmer height={isMobile ? 848 : 728} />}
          {!isLoading &&
            foodProviders?.items.map((foodProvider) => {
              const itemFloorName =
                floors?.find(
                  (floor) =>
                    floor?.sys?.id ===
                    foodProvider?.unit?.[Lang.en]?.fields?.FNO?.[Lang.en]?.sys?.id
                )?.name?.[Lang.en] ?? 'N/A';

              return (
                <div key={foodProvider.sys.id}>
                  <TabItem
                    onSelect={async () => {
                      setIsEditing(false);

                      const selected = await foodProviderRequester?.getById(foodProvider?.sys?.id);
                      if (selected) {
                        setSelectedFoodProvider(selected);
                      }

                      setFoodProviderToCreate(null);
                      setIncompleteOrderOptionCards(0);
                    }}
                    unitName={
                      foodProvider?.unit?.[Lang.en]?.fields?.WebStoreName?.[locale] ?? 'N/A'
                    }
                    propertyName={
                      foodProvider?.property?.[Lang.en]?.fields?.WebPropertyName?.[Lang.en]
                    }
                    floorName={itemFloorName}
                    status={foodProvider.status as Status}
                    lastUpdated={dateFormat.format(new Date(foodProvider.sys.updatedAt))}
                    isSelected={selectedFoodProvider?.sys?.id === foodProvider?.sys?.id}
                  />
                </div>
              );
            })}
          {foodProviders?.items.length === 0 && (
            <Text>{t('food_provider_left_panel.no_items')}</Text>
          )}
        </div>
        {!!(
          foodProviders?.total &&
          foodProviders?.items &&
          foodProviders.total > foodProviders.items.length
        ) && (
          <div className={styles.pagination}>
            <PaginationBar
              activePage={currentPage}
              setActivePage={setCurrentPage}
              totalItems={foodProviders?.total ?? 0}
              pageSize={FOOD_PROVIDERS_PER_PAGE}
              maxPages={isMobile ? 3 : 5}
              useCustomStyling
            />
          </div>
        )}
      </div>
    </>
  );
};

export default FoodProviderSidebar;
