import { AriaAttributes, AriaRole, useMemo } from 'react';
import cx from 'classnames';
import { CustomButton, Icon, Text } from 'components';
import { useTranslation } from 'hooks';
import { Colors } from 'types/color.type';
import styles from './alert-banner.module.scss';
import imageMap from '../../constants/IconUrlMap';

enum AlertBannerTypes {
  Error = 'error',
}

type AlertBannerType = `${AlertBannerTypes}`;

const alertConfig = {
  [AlertBannerTypes.Error]: {
    backgroundColor: Colors.RosePetal,
    borderColor: Colors.HorizonRed,
    icon: 'alert' as keyof typeof imageMap,
  },
};

function AlertBanner({
  id,
  type = AlertBannerTypes.Error,
  title,
  ariaLabel,
  displayIcon,
  handleClose,
  ariaLive,
  ...props
}: Readonly<AlertBannerProps>) {
  const config = useMemo(() => alertConfig[type], [type]);
  const { t } = useTranslation();

  return (
    <div
      tabIndex={-1}
      className={cx(
        styles.alertContainer,
        styles[`alertContainer-${config.backgroundColor}`],
        styles[`alertContainerBorder-${config.borderColor}`]
      )}
      aria-label={ariaLabel}
      aria-live={ariaLive}
      {...props}
    >
      <div className={styles.contentWrapper}>
        {displayIcon && (
          <div className={styles.alertIconWrapper}>
            <Icon className={styles.alertIcon} ariaHidden name={config.icon} alt="" />
          </div>
        )}
        <div className={styles.alertTitleWrapper}>
          {title && (
            <Text className={styles.alertTitle} type="h5" tag="span" color={Colors.MidnightBlue}>
              {title}
            </Text>
          )}
        </div>
      </div>
      {handleClose && (
        <div className={styles.alertButtonWrapper}>
          <CustomButton
            onClick={handleClose}
            variant="cf-text"
            className={styles.closeButton}
            label={t('generic_modal.close_label')}
            alt=""
            text=""
            iconName="close"
            iconWidth="16"
            iconHeight="16"
          />
        </div>
      )}
    </div>
  );
}

interface AlertBannerProps {
  id?: string;
  type?: AlertBannerType;
  title?: string;
  displayIcon?: boolean;
  ariaLabel: AriaAttributes['aria-label'];
  handleClose?: () => void; // Optional close handler
  ariaLive?: AriaAttributes['aria-live'];
  role?: AriaRole;
}

export default AlertBanner;
