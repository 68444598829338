enum Status {
  Published = 'Approved',
  PendingReview = 'Submitted for Approval',
  Draft = 'Draft',
  Declined = 'Declined',
}

export enum NestedStatus {
  Published = 'Approved',
  PendingCreation = 'Submitted for Creation',
  PendingDeletion = 'Submitted for Deletion',
  PendingUpdate = 'Submitted for Update',
}

export default Status;
