import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Icon, RadioButton, Tabs } from 'components';
import styles from './sort-filter.module.scss';
import { useBreakpoints } from 'hooks';
import { ModalType } from 'constants/ModalType';
import { useModalContext } from 'context/modal-context';
import { Colors } from 'types/color.type';
import {
  AccordionInputType,
  AccordionType,
  useFoodProviderContext,
} from 'context/food-provider-context';
import { InputCheckbox } from 'components/form/input-checkbox';
import { handleCheckboxOptionChange } from 'helpers/foodProvider';
import useClickOutside from 'hooks/useClickOutside';

interface SortFilterDesktopModalProps {
  title: string;
  inputType?: AccordionInputType;
  type: AccordionType;
  selectedOptionKey?: string | string[];
  onOptionChange?: (value: any) => unknown;
  options: Record<any, string> | null;
  onClose?: () => void;
  keyToDisplayFirst?: string;
}

const SortFilterDesktopModal = ({
  title,
  inputType = AccordionInputType.RadioButtons,
  type,
  selectedOptionKey,
  onOptionChange,
  options,
  onClose,
  keyToDisplayFirst,
}: SortFilterDesktopModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useClickOutside(modalRef, (_event: Event) => {
    onClose?.(); // Call the onClose function when clicking outside
  });

  const orderedKeys = keyToDisplayFirst
    ? [keyToDisplayFirst, ...Object.keys(options ?? {}).filter((key) => key !== keyToDisplayFirst)]
    : Object.keys(options ?? {});

  return (
    <div ref={modalRef} className={styles.desktopFilterModal}>
      <div className={styles.desktopFilterModalTitleWrapper}>
        <Text type="h6">{title}</Text>
      </div>
      <div className={styles.desktopFilterModalBody}>
        {!!options &&
          orderedKeys.map((optionKey) => {
            const optionValue = options[optionKey];

            const isSelected = Array.isArray(selectedOptionKey)
              ? selectedOptionKey.includes(optionKey)
              : selectedOptionKey === optionKey;

            return (
              <button
                className={styles.desktopFilterModalBodyItem}
                key={`accordion-${type}-option-${optionKey}`}
                onClick={() => onOptionChange?.(optionKey)}
              >
                {inputType === AccordionInputType.RadioButtons ? (
                  <RadioButton
                    className={styles.desktopFilterModalRadioButton}
                    containerClassName={styles.desktopFilterModalRadioButtonContainer}
                    checked={isSelected}
                    value={optionKey}
                    readOnly
                    tabIndex={-1}
                  />
                ) : (
                  <InputCheckbox
                    className={styles.desktopFilterModalCheckbox}
                    type={inputType}
                    name={type}
                    value={optionKey}
                    checked={isSelected}
                    readOnly
                    tabIndex={-1}
                  />
                )}
                <Text type="body">{optionValue}</Text>
              </button>
            );
          })}
      </div>
    </div>
  );
};

const SortFilter = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  const { setAndShowModal, setShowModal } = useModalContext();
  const {
    appliedState,
    appliedSort,
    setAppliedSort,
    appliedProperties,
    setAppliedProperties,
    stateOptions,
    sortOptions,
    propertyFilteringOptions,
  } = useFoodProviderContext();

  const [isDesktopSortModalOpen, setIsDesktopSortModalOpen] = useState<boolean>(false);
  const [isDesktopFilterModalOpen, setIsDesktopFilterModalOpen] = useState<boolean>(false);

  const sortButtonRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLDivElement>(null);
  const sortModalRef = useRef<HTMLDivElement>(null);
  const filterModalRef = useRef<HTMLDivElement>(null);

  useClickOutside([sortButtonRef, sortModalRef], (_event: Event) => {
    setIsDesktopSortModalOpen(false);
  });

  useClickOutside([filterButtonRef, filterModalRef], (_event: Event) => {
    setIsDesktopFilterModalOpen(false);
  });

  const handleApply = () => {
    setShowModal(false);
  };

  const handleClear = () => {
    setShowModal(false);
  };

  const showSortFilterModal = () =>
    setAndShowModal({
      type: ModalType.SortFilter,
      onApply: handleApply,
      onClear: handleClear,
    });

  const handleSortButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDesktopSortModalOpen(!isDesktopSortModalOpen);
    if (isDesktopFilterModalOpen) setIsDesktopFilterModalOpen(false);
  };

  const handleFilterButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDesktopFilterModalOpen(!isDesktopFilterModalOpen);
    if (isDesktopSortModalOpen) setIsDesktopSortModalOpen(false);
  };

  const handleModalClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      {isMobile ? (
        <button className={styles.filterButton} onClick={showSortFilterModal}>
          <div>
            <Text className={styles.filterButtonText} tag="span" type="body">{`${t(
              'food_provider_left_panel.filters.filter'
            )} `}</Text>
            <Text
              className={styles.filterButtonText}
              tag="span"
              type="h6"
              color={Colors.BahamaBlue}
            >
              {`${stateOptions?.[appliedState]}, ${sortOptions?.[appliedSort]}, +1`}
            </Text>
          </div>

          <Icon name="filter" alt="" width={16} height={16} />
        </button>
      ) : (
        <>
          <Tabs />
          <div className={styles.desktopFilterWrapper}>
            <div className={styles.desktopFilter}>
              <Text className={styles.filterButtonText}>{`${t(
                'food_provider_left_panel.filters.sort_by'
              )}:`}</Text>
              <button className={styles.desktopFilterButton} onClick={handleSortButtonClick}>
                <Text
                  className={styles.filterButtonText}
                  tag="span"
                  type="h6"
                  color={Colors.BahamaBlue}
                >
                  {sortOptions?.[appliedSort]}
                </Text>
                <Icon
                  className={styles.desktopFilterButtonIcon}
                  name="chevronDown"
                  alt=""
                  width={16}
                  height={16}
                />
              </button>
              <button className={styles.desktopFilterButton} onClick={handleFilterButtonClick}>
                <Text
                  className={styles.filterButtonText}
                  tag="span"
                  type="h6"
                  color={Colors.BahamaBlue}
                >
                  {`${
                    appliedProperties?.length === 1 && appliedProperties?.[0] === 'all'
                      ? t('food_provider_left_panel.filters.all_properties')
                      : t('food_provider_left_panel.filters.properties', {
                          totalProperties: appliedProperties.length,
                        })
                  }`}
                </Text>
                <Icon
                  className={styles.desktopFilterButtonIcon}
                  name="chevronDown"
                  alt=""
                  width={16}
                  height={16}
                />
              </button>
            </div>
            {isDesktopSortModalOpen && (
              <div ref={sortModalRef} onClick={handleModalClick}>
                <SortFilterDesktopModal
                  type={AccordionType.Sort}
                  title={t('food_provider_left_panel.filters.sort_by')}
                  options={sortOptions}
                  selectedOptionKey={appliedSort}
                  onOptionChange={(newKey) => {
                    if (newKey !== appliedSort) setAppliedSort(newKey);
                  }}
                  onClose={() => setIsDesktopSortModalOpen(false)}
                />
              </div>
            )}
            {isDesktopFilterModalOpen && (
              <div ref={filterModalRef} onClick={handleModalClick}>
                <SortFilterDesktopModal
                  type={AccordionType.Filter}
                  title={t('food_provider_left_panel.filters.filter')}
                  options={propertyFilteringOptions}
                  inputType={AccordionInputType.Checkboxes}
                  selectedOptionKey={appliedProperties}
                  onOptionChange={(newKey) => {
                    handleCheckboxOptionChange(
                      newKey,
                      appliedProperties,
                      setAppliedProperties,
                      propertyFilteringOptions
                    );
                  }}
                  onClose={() => setIsDesktopFilterModalOpen(false)}
                  keyToDisplayFirst="all"
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SortFilter;
