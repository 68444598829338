import UnitType from 'types/unit.type';
import { getDifferenceInDays, getFileSize, getTimeNow, getTodayString } from './form';
import { OrderOption } from 'types/orderOption.type';
import { TextFieldTypes } from 'enums/FieldTypes';
import RegexValidation from 'constants/RegexValidation';
import { InputValidation } from 'constants/InputValidation';
import LimitsValidations from './validation/limits';

export enum ValidationError {
  missing = 'MISSING',
  invalid = 'INVALID_FORMAT',
  misMatch = 'MISMATCH',
  tooLong = 'TOO_LONG',
  tooShort = 'TOO_SHORT',
  tooSmall = 'TOO_SMALL',
  matches = 'MATCHES_OLD_PASSWORD',
  dateInPast = 'DATE_IN_PAST',
  timeInPast = 'TIME_IN_PAST',
  endDateBeforeStartDate = 'END_DATE_BEFORE_START_DATE',
  endTimeBeforeStartTime = 'END_TIME_BEFORE_START_TIME',
  publishDateAfterEndDate = 'PUBLISH_DATE_AFTER_END_DATE',
  publishTimeAfterEndTime = 'PUBLISH_TIME_AFTER_END_TIME',
  publishDateAfterStartDate = 'PUBLISH_DATE_AFTER_START_DATE',
  publishTimeAfterStartTime = 'PUBLISH_TIME_AFTER_START_TIME',
  publishTimeBeforeToday = 'PUBLISH_TIME_BEFORE_TODAY',
  requiresFrench = 'REQUIRES_FRENCH',
  max = 'MAX',
}

const VALIDATION_MAP = {
  [InputValidation.PHONE]: RegexValidation.phoneNumber,
  [InputValidation.NUMBER]: RegexValidation.number,
  [InputValidation.NAME]: RegexValidation.name,
  [InputValidation.EMAIL]: RegexValidation.emailRelaxed,
  [TextFieldTypes.SYMBOL]: {
    ...RegexValidation.any,
    limits: LimitsValidations.characterLimit(256),
  },
  [TextFieldTypes.LONG_TEXT]: {
    ...RegexValidation.any,
    limits: LimitsValidations.characterLimit(50_000),
  },
  [InputValidation.SYMBOL_WEBSITE]: {
    ...RegexValidation.websiteRegex,
    limits: LimitsValidations.characterLimit(256),
  },
  [InputValidation.LONG_TEXT_WEBSITE]: {
    ...RegexValidation.websiteRegex,
    limits: LimitsValidations.characterLimit(50_000),
  },
};

type SizeValidationType = { min?: number | null; max?: number | null };

/**
 * This function validates form fields based on their strict validation type
 * @param {String} val - Field value
 * @param {Boolean} required - Is the field required?
 * @param {String} validationType - Validation types
 * @param {Function} requiredErrorCallback - On required fail..
 * @param {Function} validationErrorCallback - On validation fail...
 * @returns {Boolean} true | false
 */

const validateFormField = (
  val?: string,
  required?: boolean,
  validationType: InputValidation | TextFieldTypes = InputValidation.NONE,
  requiredErrorCallback?: (val: boolean) => any,
  validationErrorCallback?: (val: boolean) => any
) => {
  let isValid = true;
  const value = val;
  const hasValidation = validationType !== InputValidation.NONE;
  const requiredErr = required && !value;
  const requiresValidation = hasValidation && value;
  const validation = VALIDATION_MAP[validationType];

  const validateExpression = (expression: RegExp) => {
    let isValidString = false;
    if (value) {
      isValidString = expression.test(value);
    }
    if (!isValidString) {
      validationErrorCallback?.(true);
    }
    return isValidString;
  };

  const validateLength = (limits: SizeValidationType, valueLength: number) => {
    const { min, max } = limits;
    let isValidLength = true;

    if (min) {
      if (valueLength < min) {
        isValidLength = false;
      }
    }
    if (max) {
      if (valueLength > max) {
        isValidLength = false;
      }
    }

    if (!isValidLength) {
      validationErrorCallback?.(true);
    }
    return isValidLength;
  };

  if (requiredErr) {
    requiredErrorCallback?.(true);
    isValid = false;
  } else if (requiresValidation) {
    const { regexp, limits } = validation;
    const hasRegex = regexp?.pattern;

    if (hasRegex) {
      isValid = validateExpression(RegExp(regexp.pattern));
    }

    if (limits && isValid) {
      const size = (limits.size as SizeValidationType) ?? {};
      isValid = validateLength(size, value.length);
    }
  }

  return isValid;
};

export function validateEmail(value: string, required = true): ValidationError | undefined {
  if (!value && required) {
    return ValidationError.missing;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return ValidationError.invalid;
  }

  return undefined;
}

export function validateUrl(
  value?: string,
  required = true,
  fieldType: TextFieldTypes | InputValidation = InputValidation.SYMBOL_WEBSITE
): ValidationError | undefined {
  let err: ValidationError | undefined;
  const requiredCallback = (requiredErr: boolean) => {
    if (requiredErr) {
      err = ValidationError.missing;
    }
  };
  const invalidCallback = (requiredErr: boolean) => {
    if (requiredErr) {
      err = ValidationError.invalid;
    }
  };

  validateFormField(value, required, fieldType, requiredCallback, invalidCallback);

  return err;
}

export function validatePassword(value: string, required = true): ValidationError | undefined {
  if (!value && required) {
    return ValidationError.missing;
  } else if (value.length < 8) {
    return ValidationError.tooShort;
  }

  return undefined;
}

export function validateNewPassword(
  oldValue: string,
  newValue: string,
  required = true
): ValidationError | undefined {
  if (!newValue && required) {
    return ValidationError.missing;
  } else if (newValue.length < 8) {
    return ValidationError.tooShort;
  } else if (newValue === oldValue) {
    return ValidationError.matches;
  }

  return undefined;
}

export function validatePasswordConf(
  passwordNew: string,
  passwordConf: string,
  required = true
): ValidationError | undefined {
  if (!passwordConf && required) {
    return ValidationError.missing;
  } else if (passwordNew !== passwordConf) {
    return ValidationError.misMatch;
  }

  return undefined;
}

export function validateFirstName(value: string, required = true): ValidationError | undefined {
  if (!value && required) {
    return ValidationError.missing;
  } else if (value.length > 50) {
    return ValidationError.tooLong;
  }

  return undefined;
}

export function validateLastName(value: string, required = true): ValidationError | undefined {
  if (!value && required) {
    return ValidationError.missing;
  } else if (value.length > 50) {
    return ValidationError.tooLong;
  }

  return undefined;
}

export function validateStores(value: UnitType[], required = true): ValidationError | undefined {
  if (!value.length && required) {
    return ValidationError.missing;
  }

  return undefined;
}

export function validateStartDate(startDate: string, required = true): ValidationError | undefined {
  const todayDateString = getTodayString().substring(0, 10);

  if (!startDate && required) {
    return ValidationError.missing;
  } else if (startDate < todayDateString) {
    return ValidationError.dateInPast;
  }

  return undefined;
}

export function validateEndDate(
  endDate: string,
  startDate: string,
  required = true
): ValidationError | undefined {
  const todayDateString = getTodayString().substring(0, 10);

  if (!endDate && required) {
    return ValidationError.missing;
  } else if (endDate < todayDateString) {
    return ValidationError.dateInPast;
  } else if (endDate < startDate) {
    return ValidationError.endDateBeforeStartDate;
  } else if (getDifferenceInDays(startDate, endDate) > 30) {
    return ValidationError.tooLong;
  }

  return undefined;
}

export function validatePublishDate(
  publishDate: string,
  startDate: string,
  endDate: string,
  required = true
): ValidationError | undefined {
  if (!publishDate && required) {
    return ValidationError.missing;
  } else if (publishDate > startDate) {
    return ValidationError.publishDateAfterStartDate;
  } else if (getDifferenceInDays(publishDate, startDate) > 7) {
    return ValidationError.tooLong;
  } else if (publishDate > endDate) {
    return ValidationError.publishDateAfterEndDate;
  }

  return undefined;
}

export function validateStartTime(
  startTime: string,
  startDate: string,
  required = true
): ValidationError | undefined {
  const timeNow = getTimeNow();
  const todayDateString = getTodayString().substring(0, 10);

  if (!startTime && required) {
    return ValidationError.missing;
  } else if (startDate === todayDateString && startTime < timeNow) {
    return ValidationError.timeInPast;
  }

  return undefined;
}

export function validateEndTime(
  endTime: string,
  startTime: string,
  endDate: string,
  startDate: string,
  required = true
): ValidationError | undefined {
  if (!endTime && required) {
    return ValidationError.missing;
  } else if (endDate === startDate && endTime < startTime) {
    return ValidationError.endTimeBeforeStartTime;
  }

  return undefined;
}

export function validatePublishTime(
  endTime: string,
  startTime: string,
  publishTime: string,
  publishDate: string,
  endDate: string,
  startDate: string,
  required = true
): ValidationError | undefined {
  if (!publishTime && required) {
    return ValidationError.missing;
  } else if (publishDate === endDate && publishTime > endTime) {
    return ValidationError.publishTimeAfterEndTime;
  } else if (publishDate === startDate && publishTime > startTime) {
    return ValidationError.publishTimeAfterStartTime;
  } else if (new Date(`${publishDate}T${publishTime}`) < new Date()) {
    return ValidationError.timeInPast;
  }

  return undefined;
}

export function validateLocations(
  locations: string[],
  required = true
): ValidationError | undefined {
  if ((!locations || locations.length === 0) && required) {
    return ValidationError.missing;
  }

  return undefined;
}

export function validateHeadline(headline: string, required = true): ValidationError | undefined {
  if (!headline && required) {
    return ValidationError.missing;
  } else if (headline.length > 256) {
    return ValidationError.tooLong;
  }

  return undefined;
}

export function validateDescription(
  description: string,
  required = true
): ValidationError | undefined {
  if (!description && required) {
    return ValidationError.missing;
  } else if (description.length > 5000) {
    return ValidationError.tooLong;
  }

  return undefined;
}

export function validateKeywords(keywords: string[], required = true): ValidationError | undefined {
  if ((!keywords || keywords.length === 0) && required) {
    return ValidationError.missing;
  }

  return undefined;
}

export function validatePromotionCode(
  promotionCode: string,
  required = true
): ValidationError | undefined {
  if (!promotionCode && required) {
    return ValidationError.missing;
  } else if (!promotionCode.match(/^[0-9A-Za-z]*$/g)) {
    return ValidationError.invalid;
  }

  return undefined;
}

const ALLOWED_FILE_EXTENSIONS = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];

export async function validateEntryImageFile(
  img?: File | null,
  isRequired = true,
  checkMinSize = true,
  checkAspectRatio = true
): Promise<ValidationError | undefined> {
  if (!img) return isRequired ? ValidationError.missing : undefined;
  const size = await getFileSize(img);

  const aspectRatio = size.width / size.height;
  if (checkMinSize && (size.width < 1126 || size.height < 563)) {
    return ValidationError.tooSmall;
  }

  if (checkAspectRatio && (aspectRatio < 1.95 || aspectRatio > 2.05)) {
    return ValidationError.invalid;
  }

  if (ALLOWED_FILE_EXTENSIONS.indexOf(img.type) < 0) {
    return ValidationError.invalid;
  }

  return undefined;
}

export function validateOfferLocale(
  locations: string[],
  viewableUnits: Record<string, UnitType>,
  isMultilingual: boolean
) {
  if (!isMultilingual) {
    const hasFrenchLocation = locations
      .map((location) => viewableUnits[location])
      .filter(Boolean)
      .some((location) => location.property.requiresFrench);
    if (hasFrenchLocation) {
      return ValidationError.requiresFrench;
    }
  }

  return undefined;
}

export function validateOrderOptions(
  orderOptions: OrderOption[] = [],
  orderOptionsToCreate: Partial<OrderOption>[] = [],
  orderOptionsToDelete: Partial<OrderOption>[] = [],
  required = true
): ValidationError | undefined {
  const totalOrderOptions =
    orderOptions.length + orderOptionsToCreate.length - orderOptionsToDelete.length;

  if (orderOptions.length < 1 && orderOptionsToCreate.length < 1 && required) {
    return ValidationError.missing;
  } else if (totalOrderOptions > 6) {
    return ValidationError.max;
  }
}

export function validateExists(propertyId?: string, required = true): ValidationError | undefined {
  if (!propertyId && required) {
    return ValidationError.missing;
  }
}

export function validateProperty(
  propertyId?: string,
  required = true
): ValidationError | undefined {
  return validateExists(propertyId, required);
}

export function validateUnit(unitId?: string, required = true): ValidationError | undefined {
  return validateExists(unitId, required);
}

export async function validateFoodProviderImage(
  imageUrl?: string,
  newImageFile?: File | null | undefined,
  required = true
): Promise<ValidationError | undefined> {
  const imageFileValidationErrors = await validateEntryImageFile(
    newImageFile,
    required,
    false,
    true
  );

  if (required && !imageUrl && imageFileValidationErrors === ValidationError.missing) {
    return ValidationError.missing;
  }

  if (imageFileValidationErrors === ValidationError.invalid) {
    return imageFileValidationErrors;
  }
}

export function validateOrderMethods(
  orderMethods?: string[],
  required = true
): ValidationError | undefined {
  if (required && (!orderMethods || orderMethods?.length <= 0)) return ValidationError.missing;
}
