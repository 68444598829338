import axios, { AxiosInstance } from 'axios';
import Status from 'constants/Status';
import { toFormData } from 'helpers/form';
import { EntryCollection } from 'types';

import { SortOptions } from './../food-provider-context';

export interface RequesterOptions {
  apiToken: string;
  baseURL: string;
}

export type GetItemsParams = {
  status?: string;
  skip?: number;
  limit?: number;
  search?: string;
  sort?: Omit<SortOptions, SortOptions.RecentActivity> | 'recent';
  path?: string;
  properties?: string;
};

/** T: Entry type. E.g.: Offer, FoodProvider.
 *  K: Entry Base type. Extends Base class. E.g.: OfferBase, FoodProviderBase.
 *  V: Entry data type. E.g.: OfferData, FoodProviderData.
 * */
class Requester<T, K, V> {
  protected axios: AxiosInstance;

  constructor({ baseURL, apiToken }: RequesterOptions) {
    this.axios = axios.create({
      baseURL,
      headers: {
        authorization: `Bearer ${apiToken}`,
        'content-type': 'application/form-data',
      },
    });
  }

  async getItems({ search = '', path, ...otherParams }: GetItemsParams) {
    const res = await this.axios.get<EntryCollection<K>>(`${path ?? ''}/`, {
      params: {
        ...otherParams,
        search,
      },
    });

    return res.data;
  }

  async getById(id: string, path?: string) {
    const res = await this.axios.get<T>(`${path ?? ''}/${id}`);
    return res.data;
  }

  async create(data: Partial<V>, status: Status, path?: string) {
    const form = toFormData({ ...data, status });
    const res = await this.axios.post<{ id: string }>(`${path ?? ''}/`, form);
    return res.data;
  }

  async edit(id: string, data: Partial<V>, status: Status, path?: string) {
    const form = toFormData({ ...data, status });
    const res = await this.axios.put<{ id: string }>(`${path ?? ''}/${id}`, form);
    return res.data;
  }

  async discard(id: string, path?: string) {
    const res = await this.axios.delete<T>(`${path ?? ''}/${id}`);
    return res.data;
  }
}

export default Requester;
