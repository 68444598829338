import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { Helmet } from 'react-helmet';
import Header from '../header/header';
import Footer from '../footer/footer';
import styles from './layout.module.scss';
import Color from 'types/color.type';
import { useBreakpoints, useTranslation } from 'hooks';
import imageMap from '../../constants/IconUrlMap';
import { AlertBanner } from 'components';
import { DeploymentStatusService } from 'services';
import { useAuthContext } from 'context/auth-context';

const Layout = ({
  children,
  pageTitle,
  pageDescription,
  withFooterNavBar = false,
  showFooterNav = false,
  showHeaderNav = false,
  showTapestryLeft = false,
  showTapestryRight = false,
  backgroundColor = 'white',
  showTapestryOnMobile = false,
  tapestryName = 'tapestryTopLeft',
  className,
}: LayoutProps) => {
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const deploymentBannerText = t('ongoing_deployment') ?? '';
  const [displayDeploymentBanner, setDisplayDeploymentBanner] = useState<boolean>(false);

  const canShowTapestry = isMobile ? showTapestryOnMobile : true;

  // Use useRef to store the DeploymentStatusService instance so it is not recreated on every render
  const deploymentStatusServiceRef = useRef<DeploymentStatusService | null>(null);

  useEffect(() => {
    if (token && !deploymentStatusServiceRef.current) {
      deploymentStatusServiceRef.current = new DeploymentStatusService({
        apiToken: token,
      });
    }
  }, [token]); // Recreate the service only when the token changes

  useEffect(() => {
    // Check backend to see if deployment is ongoing to toggle the deployment banner
    const fetchDeploymentStatus = async () => {
      if (!deploymentStatusServiceRef.current) {
        return;
      }

      try {
        const result = await deploymentStatusServiceRef.current.getDeploymentStatus();

        if (result && displayDeploymentBanner !== result?.isDeploymentOngoing) {
          setDisplayDeploymentBanner(result.isDeploymentOngoing);
        }
      } catch (err) {
        console.log(`ERROR fetching deployment status: ${err}`);
      }
    };

    fetchDeploymentStatus();
  }, [token]);

  return (
    <div className={styles[`bg-${backgroundColor}`]}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>

      {displayDeploymentBanner && (
        <AlertBanner
          ariaLabel={deploymentBannerText}
          ariaLive="assertive"
          role="alert"
          title={deploymentBannerText}
          displayIcon
          handleClose={() => {
            setDisplayDeploymentBanner(false);
          }}
        />
      )}

      <Header displayNav={showHeaderNav} />

      <main className={styles.content}>
        {canShowTapestry && (showTapestryLeft || showTapestryRight) && (
          <img
            src={imageMap[tapestryName]}
            alt=""
            className={cx(
              styles.tapestry,
              {
                [styles['tapestry--left']]: showTapestryLeft,
                [styles['tapestry--right']]: showTapestryRight,
                [styles['tapestry--reduced']]: tapestryName === 'tapestryTop',
              },
              className
            )}
          />
        )}
        {children}
      </main>
      <Footer withNavBar={withFooterNavBar} withNavItems={showFooterNav} />
    </div>
  );
};

export interface LayoutProps {
  children?: React.ReactNode;
  pageTitle: string;
  pageDescription: string;
  withFooterNavBar?: boolean;
  showFooterNav?: boolean;
  showHeaderNav?: boolean;
  showTapestryLeft?: boolean;
  showTapestryRight?: boolean;
  backgroundColor?: Color;
  showTapestryOnMobile?: boolean;
  tapestryName?: string;
  className?: string;
}

export default Layout;
