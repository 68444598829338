import { Col, Row, Container } from 'react-bootstrap';
import styles from './FoodProvidersPage.module.scss';
import { useBreakpoints, useTranslation } from 'hooks';
import { Layout } from 'components';
import { Colors } from 'types/color.type';
import FoodProviderSidebar from 'components/food-provider/food-provider-sidebar/food-provider-sidebar';
import FoodProviderForm from 'components/food-provider/food-provider-form/food-provider-form';
import { useFoodProviderContext } from 'context/food-provider-context';

function FoodProvidersPage() {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const { selectedFoodProvider } = useFoodProviderContext();

  return (
    <Layout
      pageTitle={t('metadata:foodProvidersPage.pageTitle')}
      pageDescription={t('metadata:foodProvidersPage.description')}
      backgroundColor={Colors.PaleBlue}
      showTapestryLeft
      showFooterNav
      showHeaderNav
    >
      <Container className={styles.container}>
        <Row className={styles.containerRow}>
          {(!isMobile || !selectedFoodProvider) && (
            <Col xs={12} md={6} lg={5} className={isMobile ? 'mb-3' : ''}>
              <div className={styles.mainLeft}>
                <FoodProviderSidebar />
              </div>
            </Col>
          )}
          {(!isMobile || selectedFoodProvider) && (
            <Col xs={12} md={6} lg={7}>
              <div className={styles.mainRight}>
                <FoodProviderForm />
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </Layout>
  );
}

export default FoodProvidersPage;
