import { ChangeEvent, useEffect, useState } from 'react';
import { merge } from 'lodash-es';
import cx from 'classnames';
import { TextInput, Text, CustomButton, SingleSelect } from 'components';
import Lang from 'constants/Locale';
import styles from './order-option-card.module.scss';
import { useFoodProviderContext } from 'context/food-provider-context';
import { Colors } from 'types/color.type';
import { useBreakpoints, useTranslation } from 'hooks';
import { InputCheckbox } from 'components/form/input-checkbox';
import { useAppContext } from 'context/app-context';
import { SingleSelectOption } from 'components/single-select/single-select';
import { OrderOption } from 'types/orderOption.type';
import { validateUrl } from 'helpers/validator';
import { InputValidation } from 'constants/InputValidation';

export enum OrderOptionCardType {
  Unselected = 'unselected', // A service hasn't been selected for this option
  Existing = 'existing', // This option already existed
  New = 'new', // This is a new option that will be created
}

const OrderOptionCard = ({
  orderOption,
  textInputClassName,
  onDelete,
  onSelect,
  error: propError,
  type = OrderOptionCardType.Unselected,
  isReadOnly = false,
  setIsEditing,
}: OrderOptionCardProps) => {
  const {
    selectedOrderOptions,
    setSelectedOrderOptions,
    orderOptionsToCreate,
    setOrderOptionsToCreate,
    orderMethodOptions,
    orderServiceOptions,
    displayFrenchFields,
  } = useFoodProviderContext();
  const { t } = useTranslation(['common', 'error']);
  const { isMobile } = useBreakpoints();
  const { locale } = useAppContext();

  const orderServiceId = orderOption?.orderService?.[Lang.en]?.sys?.id;

  const orderServiceName = orderOption?.orderService?.[Lang.en]?.fields?.Name?.[Lang.en];

  const defaultSelectedOption =
    orderServiceId && orderServiceName ? { value: orderServiceId, label: orderServiceName } : null;

  const [selectedOption, setSelectedOption] = useState<SingleSelectOption | null>(
    defaultSelectedOption
  );
  const [availableOptions, setAvailableOptions] = useState<SingleSelectOption[]>([]);

  const [combinedOptions, setCombinedOptions] = useState<SingleSelectOption[]>(
    defaultSelectedOption ? [defaultSelectedOption] : []
  );

  const error = propError ?? (type === OrderOptionCardType.Unselected && !selectedOption);

  const serviceLinks = orderOption?.orderUrl?.[Lang.en]?.fields?.WebLink;
  const [englishServiceLink, setEnglishServiceLink] = useState<string>(
    serviceLinks?.[Lang.en] ?? ''
  );
  const [frenchServiceLink, setFrenchServiceLink] = useState<string>(serviceLinks?.[Lang.fr] ?? '');

  const textFields: {
    lang: Lang;
    label: string;
    value: string;
    editingFieldTitle?: string;
    setValue?: React.Dispatch<React.SetStateAction<string>>;
  }[] = [
    {
      lang: Lang.en,
      label: t('food_provider_edit.service_link_title', {
        language: t('english'),
      }),
      value: englishServiceLink,
      setValue: setEnglishServiceLink,
    },
    {
      lang: Lang.fr,
      label: t('food_provider_edit.service_link_title', {
        language: t('french'),
      }),
      value: frenchServiceLink,
      editingFieldTitle: t('food_provider_edit.optional_field', {
        fieldValue: t('food_provider_edit.service_link_title', {
          language: t('french'),
        }),
      }),
      setValue: setFrenchServiceLink,
    },
  ];

  useEffect(() => {
    const updatedAvailableOptions = orderServiceOptions?.reduce<SingleSelectOption[]>(
      (acc: SingleSelectOption[], currentOrderService) => {
        const isServiceAvailable =
          !selectedOrderOptions.some(
            (selectedOrderOption) =>
              currentOrderService?.sys?.id === selectedOrderOption?.orderService?.[Lang.en]?.sys?.id
          ) &&
          !orderOptionsToCreate.some(
            (orderOptionToCreate) =>
              currentOrderService?.sys?.id === orderOptionToCreate?.orderService?.[Lang.en]?.sys?.id
          );

        return isServiceAvailable
          ? ([
              ...acc,
              {
                value: currentOrderService?.sys?.id,
                label: currentOrderService?.name?.[Lang.en],
              },
            ] as SingleSelectOption[])
          : acc;
      },
      [] as SingleSelectOption[]
    );

    setAvailableOptions(updatedAvailableOptions ?? []);
  }, [selectedOrderOptions, orderOptionsToCreate]);

  useEffect(() => {
    setCombinedOptions([
      ...new Set([...(selectedOption ? [selectedOption] : []), ...availableOptions]),
    ]);
  }, [selectedOption, availableOptions]);

  const updateOrderOption = (updatedOrderOption: Partial<OrderOption>) => {
    switch (type) {
      case OrderOptionCardType.Existing:
        setSelectedOrderOptions([
          ...selectedOrderOptions.map((originalOrderOption) =>
            originalOrderOption?.sys?.id !== orderOption?.sys?.id
              ? originalOrderOption
              : (updatedOrderOption as OrderOption)
          ),
        ]);
        break;
      case OrderOptionCardType.New:
        setOrderOptionsToCreate([
          ...orderOptionsToCreate.map((originalOrderOption) =>
            originalOrderOption?.orderService?.[Lang.en]?.sys?.id !==
            updatedOrderOption?.orderService?.[Lang.en]?.sys?.id
              ? originalOrderOption
              : updatedOrderOption
          ),
        ]);
        break;
      default:
        break;
    }
  };

  const updateServiceLink = (language: Lang, newValue: string) => {
    const currentEnglishValue = orderOption?.orderUrl?.[Lang.en]?.fields?.WebLink?.[Lang.en];

    const updatedOrderOption =
      language === Lang.en
        ? merge(orderOption, {
            orderUrl: {
              [Lang.en]: {
                fields: {
                  WebLink: {
                    [language]: newValue,
                  },
                },
              },
            },
          })
        : merge(orderOption, {
            orderUrl: {
              [Lang.en]: {
                fields: {
                  WebLink: {
                    [Lang.en]: currentEnglishValue ?? '',

                    [Lang.fr]: newValue,
                  },
                },
              },
            },
          });
    updateOrderOption(updatedOrderOption);
  };

  const updateOrderMethods = (isChecked: boolean, orderMethodId?: string) => {
    const updatedOrderOption = orderOption ?? {};

    if (isChecked) {
      updatedOrderOption.orderMethods =
        updatedOrderOption?.orderMethods?.filter?.(
          (filteredOrderMethodId: string) => filteredOrderMethodId !== orderMethodId
        ) ?? [];
    } else if (orderMethodId) {
      updatedOrderOption.orderMethods = [
        ...(updatedOrderOption?.orderMethods ?? []),
        orderMethodId,
      ];
    }

    updateOrderOption(updatedOrderOption);
  };

  return (
    <div className={styles.orderOptionCard} key={`order-option-card-${orderServiceName ?? 'new'}`}>
      {isReadOnly ? (
        <div className={styles.readOnly}>
          <img
            className={styles.readOnlyImage}
            src={
              orderServiceOptions?.find(
                (orderService) =>
                  orderService?.sys?.id === orderOption?.orderService?.[Lang.en]?.sys?.id
              )?.imageUrl?.[Lang.en]
            }
            alt=""
            height={isMobile ? 24 : undefined}
            width={isMobile ? undefined : 130}
          />
          <div className={styles.readOnlyFields}>
            {orderMethodOptions && orderMethodOptions?.length > 0 && (
              <div className={styles.readOnlyMethod}>
                {orderMethodOptions
                  ?.filter(
                    (orderMethodOption) =>
                      orderMethodOption?.sys?.id &&
                      orderOption?.orderMethods?.includes(orderMethodOption?.sys?.id)
                  )
                  ?.map((orderMethodOption) => (
                    <div
                      className={styles.readOnlyMethodItem}
                      key={`order-method-${orderMethodOption?.sys?.id}`}
                    >
                      {orderMethodOption?.method?.[locale]}
                    </div>
                  ))}
              </div>
            )}

            {textFields
              .filter((field) => field.lang !== Lang.fr || displayFrenchFields)
              .map((field) => (
                <div key={`field-wrapper-${field.label}`} className={styles.readOnlyLink}>
                  {field.value ? (
                    <>
                      <Text
                        type="body"
                        color={Colors.CFDarkGrey}
                        className={styles.readOnlyLinkText}
                      >
                        {field.label}
                      </Text>
                      <Text
                        type="body"
                        color={Colors.CFBlue}
                        className={cx(styles.readOnlyLinkText, styles.readOnlyLinkTextEllipsis)}
                      >
                        {field.value}
                      </Text>
                    </>
                  ) : (
                    <CustomButton
                      text={field.label}
                      variant="cf-text"
                      alt=""
                      iconName="plusBlue"
                      iconHeight="24"
                      iconWidth="24"
                      onClick={() => setIsEditing?.(isReadOnly)}
                      className={styles.readOnlyButton}
                      textCustomStyle={styles.readOnlyButtonText}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      ) : (
        <>
          <div
            className={cx(styles.orderOptionService, { [styles.orderOptionServiceError]: error })}
          >
            <div className={styles.orderOptionServiceSelect}>
              <Text type="body" color={Colors.CFDarkGrey}>
                {t('food_provider_edit.service_title')}
              </Text>
              <SingleSelect
                customStyled
                error={error}
                errorText={t('error:food_provider_form.order_service.MISSING')}
                name={`select-order-option-${orderServiceId}`}
                value={selectedOption?.value ?? ''}
                options={combinedOptions}
                placeholder={{
                  value: '',
                  label: t('food_provider_edit.service_dropdown_placeholder'),
                }}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const newSelection = availableOptions?.find(
                    (availableOption) => availableOption.value === e.target.value
                  );

                  if (type !== OrderOptionCardType.Unselected && newSelection) {
                    setSelectedOption(newSelection);
                  }

                  if (onSelect) {
                    onSelect(e);
                  }
                }}
              />
            </div>
            <CustomButton
              label={t('food_provider_edit.delete_order_option')}
              variant="cf-text"
              alt=""
              iconName="trashRed"
              iconHeight="26"
              iconWidth="18"
              className={cx(styles.orderOptionServiceButton, {
                [styles.orderOptionServiceButtonError]: error,
              })}
              onClick={onDelete}
            />
          </div>
          {type !== OrderOptionCardType.Unselected && (
            <>
              <div className={styles.method}>
                <Text type="body" color={Colors.CFDarkGrey}>
                  {t('food_provider_edit.order_method_title')}
                </Text>
                {orderOption?.orderService && orderMethodOptions && (
                  <>
                    <div className={styles.methodOptionWrapper}>
                      {orderMethodOptions?.map((orderMethodOption) => {
                        const name = orderMethodOption?.method?.[locale];
                        const id = orderMethodOption?.sys?.id;
                        const isChecked = !!id && !!orderOption?.orderMethods?.includes(id);
                        return (
                          <div key={`checkbox-${name}`} className={styles.checkboxWrapper}>
                            <InputCheckbox
                              className={styles.checkbox}
                              checked={isChecked}
                              label={name}
                              onChange={() => updateOrderMethods(isChecked, id)}
                            />
                          </div>
                        );
                      })}
                    </div>
                    {(!orderOption?.orderMethods || orderOption?.orderMethods?.length <= 0) && (
                      <Text type="bodySm" color={Colors.HorizonRed}>
                        {t('error:food_provider_form.order_method.MISSING')}
                      </Text>
                    )}
                  </>
                )}
              </div>
              {textFields.map((field) => {
                const { lang, label, value, setValue } = field;

                const isUrlValid =
                  validateUrl(value, true, InputValidation.LONG_TEXT_WEBSITE) === undefined;
                const hasError = (lang === Lang.en && !value) || (!isUrlValid && value !== '');

                return (
                  <div key={`service-link-${lang}`}>
                    <Text type="body" color={Colors.CFDarkGrey}>
                      {field.editingFieldTitle ?? label}
                    </Text>
                    <TextInput
                      name="link"
                      translationGroup="food_provider_form"
                      error={hasError}
                      errorText={t(isUrlValid ? 'MISSING' : 'INVALID_FORMAT')}
                      id={`service-link-input-${lang}`}
                      type="text"
                      value={value}
                      label={label}
                      className={textInputClassName}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setValue?.(e.target.value);
                        updateServiceLink(lang, e.target.value);
                      }}
                    />
                  </div>
                );
              })}
            </>
          )}
        </>
      )}
    </div>
  );
};

interface OrderOptionCardProps {
  orderOption?: Partial<OrderOption>;
  textInputClassName?: string;
  onDelete?: () => unknown;
  onSelect?: (e: any) => unknown;
  error?: boolean;
  type?: OrderOptionCardType;
  isReadOnly?: boolean;
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default OrderOptionCard;
