import { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { useBreakpoints, useTranslation } from 'hooks';
import GenericModal from '../GenericModal';
import styles from './AddImageModal.module.scss';
import { Gallery, Icon, Text } from 'components';
import RadioButton from 'components/radio-button/radio-button';
import { Colors } from 'types/color.type';
import { handleFileUrl } from 'components/form/image-input';

export enum ImageModalOptions {
  Local = 'local',
  Gallery = 'gallery',
}

const getModalOptionLabels = (t: any) => {
  return {
    [ImageModalOptions.Local]: t('food_provider_image_modal.upload_radio_button'),
    [ImageModalOptions.Gallery]: t('food_provider_image_modal.select_radio_button'),
  };
};

const gallery = [
  '/images/offer-default/offer_image_1.jpg',
  '/images/offer-default/offer_image_2.jpg',
  '/images/offer-default/offer_image_3.jpg',
  '/images/offer-default/offer_image_1_fr.jpg',
  '/images/offer-default/offer_image_2_fr.jpg',
  '/images/offer-default/offer_image_3_fr.jpg',
]; // TODO: Replace for Food Provider images once provided

export async function linkToFile(link: string, name: string) {
  const blob = await fetch(link).then((image) => image.blob());
  const file = new File([blob], name, {
    type: blob.type,
  });
  return file;
}

const AddImageModal = ({ title, confirmButtonText, onAddImage }: AddImageModalProps) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  const [selectedOption, setSelectedOption] = useState<ImageModalOptions>(ImageModalOptions.Local);

  const [dragging, setDragging] = useState(false);
  const [draftValue, setDraftValue] = useState<File>();
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [value, setValue] = useState<File | null>(null);

  const labels = useMemo(() => getModalOptionLabels(t), [t]);

  const [announcement, setAnnouncement] = useState('');

  const inputFile = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setAnnouncement(
        t('common:food_provider_image_modal.radio_selected', {
          option: labels[selectedOption],
        })
      );
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (value) {
      handleFileUrl(setFileUrl, value);
    } else {
      setFileUrl(null);
    }
  }, [value]);

  const handleModalOptionClick = (option: ImageModalOptions) => {
    setAnnouncement(
      t('common:food_provider_image_modal.radio_selected', {
        option: labels[option],
      })
    );
    setSelectedOption(option);
  };

  return (
    <GenericModal
      title={title}
      show
      confirmButtonText={confirmButtonText}
      cancelButtonText={t('cancel')}
      headerClassName={styles.modalHeader}
      cancelButtonClassName={styles.modalButton}
      acceptButtonClassName={styles.modalButton}
      handleAccept={() => {
        onAddImage?.(selectedOption, value, draftValue);
      }}
      footerClassName={styles.modalFooter}
      reverseButtonOrder={!isMobile}
    >
      <div className={styles.modalOptionContainer} role="radiogroup">
        {Object.values(ImageModalOptions).map((option, i) => (
          <button
            className={styles.modalOption}
            key={option}
            onClick={() => handleModalOptionClick(option)}
            role="radio"
            aria-checked={selectedOption === option}
            aria-labelledby={`image-select-label-${i}`}
            tabIndex={0}
          >
            <RadioButton
              name="image-select-type"
              readOnly
              tabIndex={-1}
              value={option}
              checked={selectedOption === option}
              aria-hidden="true"
              className={styles.modalOptionRadioButton}
              containerClassName={styles.modalOptionRadioButtonContainer}
            />
            <Text id={`image-select-label-${i}`} className={styles.modalOptionText} type="body">
              {labels[option]}
            </Text>
          </button>
        ))}
        {/* Announce selection change for screen readers */}
        <div aria-live="polite" aria-atomic="true" className="sr-only">
          {announcement}
        </div>
      </div>

      {selectedOption === ImageModalOptions.Local && (
        <section className={styles.modalImageContainer}>
          {fileUrl && (
            <>
              <div className={styles.modalSelectedImageContainer}>
                <img alt={value?.name ?? ''} className={styles.preview} src={fileUrl} />
              </div>
              <button
                onClick={() => {
                  inputFile.current!.value = '';
                  setValue(null);
                }}
                className={styles.delete}
                aria-label={t('image_picker.discard')}
              >
                <Icon name="discard" alt="" aria-hidden="true" />
              </button>
            </>
          )}
          <div className={cx(styles.modalDrag, { [styles.imageSelected]: !!fileUrl })}>
            <div className={styles.modalDragContent}>
              <Icon name="add" alt="" aria-hidden="true" />
              <div>
                {!isMobile && (
                  <>
                    <Text color={Colors.Black}>{t('food_provider_image_modal.drag')}</Text>{' '}
                  </>
                )}
                <button
                  onDragLeave={() => setDragging(false)}
                  onDragOver={() => setDragging(true)}
                  onDrop={() => setDragging(false)}
                  onClick={() => inputFile?.current?.click()}
                  className={cx(styles.modalDragContentUpload, { [styles.dragging]: dragging })}
                  aria-label={t('image_picker.instruction')}
                >
                  <Text color={Colors.CFBlue}>
                    {t(`food_provider_image_modal.upload_cta${isMobile ? '_uppercase' : ''}`)}
                  </Text>
                  <input
                    ref={inputFile}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      const file = e?.currentTarget?.files?.item(0);
                      if (file) setValue(file);
                    }}
                    className={cx(styles.input)}
                    disabled={false}
                    accept="image/*"
                    type="file"
                    tabIndex={-1}
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
      {selectedOption === ImageModalOptions.Gallery && (
        <Gallery draftValue={draftValue} setDraftValue={setDraftValue} /> // TODO: Add Food Provider images (aka galleryItems) once they're provided by business
      )}
    </GenericModal>
  );
};

export interface AddImageModalProps {
  title: string;
  confirmButtonText?: string;
  onAddImage?: (
    selectedOption: ImageModalOptions,
    uploadedFile?: File | null,
    gallerySelectedFile?: File | null | undefined
  ) => void;
}

export default AddImageModal;
