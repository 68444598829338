const OFFER_API_ENDPOINT = '/api/offer';
const FOOD_PROVIDER_API_ENDPOINT = '/api/foodProvider';
const SIGNUP_API_ENDPOINT = '/api/signup';
const CATEGORIES_API_ENDPOINT = '/api/retailCategory';
const UNIT_API_ENDPOINT = '/api/unit';
const USER_API_ENDPOINT = '/api/user';
const PROPERTY_API_ENDPOINT = '/api/property';
const RETAILER_GROUP_API_ENDPOINT = '/api/retailerGroup';
const FORGOT_PASSWORD_API_ENDPOINT = '/api/auth/forgotPassword';
const VERIFY_RECOVERY_TOKEN_API_ENDPOINT = '/api/auth/verifyRecoveryToken';
const RESET_PASSWORD_TOKEN_API_ENDPOINT = '/api/auth/resetPassword/token';
const DEPLOYMENT_STATUS_API_ENDPOINT = '/api/deploymentStatus';

const {
  REACT_APP_OKTA_DOMAIN,
  REACT_APP_OKTA_CLIENT_ID,
  REACT_APP_CF_HELP_EMAIL,
  REACT_APP_GOOGLE_TAG_MANAGER_ID,
  REACT_APP_GOOGLE_ANALYTICS_TAG_ID,
  REACT_APP_CONSENT_AUTO_BLOCK_URL,
  REACT_APP_CONSENT_SDK_STUB_URL,
  REACT_APP_CONSENT_SDK_STUB_ID,
} = process.env as {
  [key: string]: string;
};

const REACT_APP_ENABLE_GOOGLE_ANALYITCS: boolean =
  process.env.REACT_APP_ENABLE_GOOGLE_ANALYITCS === 'true';

const REACT_APP_ANALYTICS_ENABLE_DEBUG_MODE: boolean =
  process.env.REACT_APP_ANALYTICS_ENABLE_DEBUG_MODE === 'true';

export {
  OFFER_API_ENDPOINT,
  FOOD_PROVIDER_API_ENDPOINT,
  SIGNUP_API_ENDPOINT,
  CATEGORIES_API_ENDPOINT,
  UNIT_API_ENDPOINT,
  PROPERTY_API_ENDPOINT,
  RETAILER_GROUP_API_ENDPOINT,
  REACT_APP_OKTA_DOMAIN,
  REACT_APP_OKTA_CLIENT_ID,
  FORGOT_PASSWORD_API_ENDPOINT,
  USER_API_ENDPOINT,
  VERIFY_RECOVERY_TOKEN_API_ENDPOINT,
  RESET_PASSWORD_TOKEN_API_ENDPOINT,
  DEPLOYMENT_STATUS_API_ENDPOINT,
  REACT_APP_CF_HELP_EMAIL,
  REACT_APP_GOOGLE_TAG_MANAGER_ID,
  REACT_APP_GOOGLE_ANALYTICS_TAG_ID,
  REACT_APP_ENABLE_GOOGLE_ANALYITCS,
  REACT_APP_CONSENT_AUTO_BLOCK_URL,
  REACT_APP_CONSENT_SDK_STUB_URL,
  REACT_APP_CONSENT_SDK_STUB_ID,
  REACT_APP_ANALYTICS_ENABLE_DEBUG_MODE,
};
