import styles from './store-select.module.scss';
import { useTranslation } from 'hooks';
import { SingleSelect, Text } from 'components';
import { useFoodProviderContext } from 'context/food-provider-context';
import { useAppContext } from 'context/app-context';
import Lang from 'constants/Locale';
import { Colors } from 'types/color.type';
import { unitToNestedUnit } from 'helpers/foodProvider';
import { SingleSelectOption } from 'components/single-select/single-select';

const FoodProviderStoreSelect = () => {
  const { t } = useTranslation(['common', 'error']);
  const { locale } = useAppContext();

  const {
    foodProviderToCreate,

    setSelectedFoodProvider,

    availablePropertyOptions,
    availableUnits,
  } = useFoodProviderContext();

  const foodProviderToCreateSelectedProperty = foodProviderToCreate?.property?.[Lang.en]?.sys?.id;

  return (
    <>
      <div className={styles.property}>
        <Text type="body" color={Colors.CFDarkGrey} className={styles.readOnlyLinkText}>
          {t('food_provider_edit.property')}
        </Text>
        <SingleSelect
          customStyled
          name="property"
          error={!foodProviderToCreateSelectedProperty}
          errorText={t('error:food_provider_form.property.MISSING')}
          value={foodProviderToCreateSelectedProperty ?? ''}
          options={Object.entries(availablePropertyOptions ?? {})?.map(([key, value]) => ({
            label: value,
            value: key,
          }))}
          placeholder={{
            value: '',
            label: t('food_provider_edit.property_placeholder'),
          }}
          onChange={(e: any) => {
            if (e.target.value) {
              setSelectedFoodProvider({
                ...foodProviderToCreate,
                property: {
                  [Lang.en]: {
                    sys: {
                      id: e.target.value,
                    },
                  },
                },
              });
            }
          }}
        />
      </div>
      <div className={styles.property}>
        <Text type="body" color={Colors.CFDarkGrey} className={styles.readOnlyLinkText}>
          {t('food_provider_edit.store')}
        </Text>
        <SingleSelect
          disabled={!foodProviderToCreateSelectedProperty}
          customStyled
          name="unit"
          error={!foodProviderToCreate?.unit?.[Lang.en]?.sys?.id}
          errorText={t('error:food_provider_form.unit.MISSING')}
          value={foodProviderToCreate?.unit?.[Lang.en]?.sys?.id ?? ''}
          options={availableUnits?.reduce((acc: SingleSelectOption[], unit) => {
            if (
              !foodProviderToCreateSelectedProperty ||
              foodProviderToCreateSelectedProperty === unit?.property?.sys?.id
            ) {
              return [
                ...acc,
                {
                  label: `${unit?.fno?.name} - ${
                    locale === Lang.en
                      ? unit?.webStoreName
                      : unit?.webStoreNameFrench ?? unit?.webStoreName
                  }`,
                  value: unit.sys.id,
                },
              ];
            }
            return acc;
          }, [])}
          placeholder={{
            value: '',
            label: t('food_provider_edit.store_placeholder'),
          }}
          onChange={(e: any) => {
            const newSelection = availableUnits?.find((unit) => e.target.value === unit?.sys?.id);

            if (newSelection) {
              const transformedUnit = unitToNestedUnit(newSelection);

              setSelectedFoodProvider({
                ...foodProviderToCreate,
                unit: {
                  [Lang.en]: {
                    ...transformedUnit,
                  },
                },
              });
            }
          }}
        />
      </div>
    </>
  );
};

export default FoodProviderStoreSelect;
