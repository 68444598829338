import cx from 'classnames';
import styles from './single-fields.module.scss';
import { useTranslation } from 'hooks';
import { Icon, Shimmer, Text, TextInput } from 'components';
import Lang from 'constants/Locale';
import { useFoodProviderContext } from 'context/food-provider-context';
import { useModalContext } from 'context/modal-context';
import { ModalType } from 'constants/ModalType';
import {
  FieldTypes,
  FormItemLanguageProps,
  FormItemProps,
  ReadOnlyButton,
} from '../food-provider-form';
import { Colors } from 'types/color.type';
import { ImageModalOptions } from 'components/modal/modals/AddImageModal';
import { validateUrl, validateFoodProviderImage } from 'helpers/validator';
import { useEffect, useState } from 'react';

type FormFieldProps = {
  languageFields: FormItemLanguageProps;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  type?: FieldTypes;
  isLoading?: boolean;
};

const ReadOnlyValue = ({
  languageFields,
  setIsEditing,
  type = FieldTypes.Text,
  isLoading = false,
}: FormFieldProps) => {
  const loadedTextComponent = languageFields.value ? (
    <Text
      type="body"
      color={Colors.CFBlue}
      className={cx(styles.readOnlyLinkText, styles.readOnlyLinkTextEllipsis)}
    >
      {languageFields.value}
    </Text>
  ) : (
    <ReadOnlyButton title={languageFields.label} setIsEditing={setIsEditing} />
  );

  const loadedImageComponent = languageFields.value ? (
    <img
      alt=""
      src={languageFields.value}
      className={cx(styles.imageDisplay, styles.imageDisplayReadOnly)}
    />
  ) : (
    <button className={styles.imageCTAContainer} onClick={() => setIsEditing(true)}>
      <Icon name="add" alt="" width={'24'} height={'24'} className={styles.imageDisplayIcon} />
      <Text color={Colors.CFBlue}>{languageFields?.cta}</Text>
    </button>
  );

  if (type === FieldTypes.Text) {
    return isLoading ? <Shimmer height={24} /> : <>{loadedTextComponent}</>;
  } else if (type === FieldTypes.Image) {
    return isLoading ? <Shimmer height={144} width={288} /> : <>{loadedImageComponent}</>;
  }
  return <></>;
};

const EditingValue = ({
  languageFields,
  type = FieldTypes.Text,
  handleClick,
  lang,
  errorText,
}: Omit<FormFieldProps, 'setIsEditing'> & {
  handleClick?: () => unknown;
  lang: Lang;
  errorText?: string;
}) => {
  const { t } = useTranslation(['error']);
  const {
    newImageFile,
    newFrenchImageFile,

    selectedFoodProvider,
  } = useFoodProviderContext();

  const [enImageValidation, setEnImageValidation] = useState<string | undefined>();
  const [frImageValidation, setFrImageValidation] = useState<string | undefined>();

  useEffect(() => {
    if (type === FieldTypes.Image) {
      const validateImage = async () => {
        const englishImageFileValidation = await validateFoodProviderImage(
          selectedFoodProvider?.imageUrl?.[Lang.en],
          newImageFile
        );
        const frenchImageFileValidation = await validateFoodProviderImage(
          selectedFoodProvider?.imageUrl?.[Lang.fr],
          newFrenchImageFile,
          false
        );

        setEnImageValidation(englishImageFileValidation);
        setFrImageValidation(frenchImageFileValidation);
      };

      validateImage();
    }
  }, [newImageFile, newFrenchImageFile]);

  const validateInput = (val?: string) => {
    const isUrlValid =
      validateUrl(val, languageFields.required, languageFields.validation) === undefined;
    return isUrlValid;
  };
  const [isValid, setIsValid] = useState(validateInput(languageFields.value));
  if (type === FieldTypes.Text) {
    return (
      <TextInput
        translationGroup="food_provider_form"
        name="link"
        errorText={errorText}
        error={!isValid}
        id={languageFields.label}
        type="text"
        value={languageFields.value ?? ''}
        label={languageFields.label}
        required={languageFields.required}
        disabled={languageFields.disabled}
        placeholder={languageFields.placeholder}
        className={styles.textInput}
        errorClassName={styles.textInputError}
        onChange={(e) => {
          languageFields.onChange(e);
          setIsValid(validateInput(e.target.value));
          if (languageFields.setValue) {
            languageFields.setValue(e.target.value);
          }
        }}
      />
    );
  } else if (type === FieldTypes.Image) {
    const hasValue = !!languageFields.value;

    return (
      <>
        <button
          className={cx(styles.imageEditingContainer, {
            [styles.imageEditingContainerCTA]: !hasValue,
          })}
          onClick={handleClick}
          aria-label={t('common:food_provider_image_modal.title', {
            language: t(`common:${lang}`),
          })}
        >
          {hasValue ? (
            <img src={languageFields.value} className={styles.imageDisplay} alt="" />
          ) : (
            <>
              <Icon
                name="add"
                alt=""
                width={'24'}
                height={'24'}
                className={styles.imageDisplayIcon}
                ariaHidden
              />
              <Text color={Colors.CFBlue}>{languageFields?.cta}</Text>
            </>
          )}
        </button>{' '}
        {!!enImageValidation && lang === Lang.en && (
          <Text type="bodySm" color={Colors.HorizonRed}>
            {t(`error:form.image.${enImageValidation}`)}
          </Text>
        )}
        {!!frImageValidation && lang === Lang.fr && (
          <Text type="bodySm" color={Colors.HorizonRed}>
            {t(`error:form.imageFrench.${frImageValidation}`)}
          </Text>
        )}
      </>
    );
  }
  return <></>;
};

const FoodProviderSingleFields = ({ formItem }: { formItem: FormItemProps }) => {
  const { t } = useTranslation(['common', 'error']);
  const { setShowModal, setAndShowModal } = useModalContext();

  const {
    displayFrenchFields,

    isEditing,
    setIsEditing,

    isLoading,

    setNewImageFile,
    setNewFrenchImageFile,
  } = useFoodProviderContext();

  const handleImageModalClick = (title?: string, lang?: Lang) => {
    setAndShowModal({
      show: true,
      type: ModalType.Image,
      title: title,
      handleClose: () => {
        setShowModal(false);
      },
      confirmButtonText: t('food_provider_view.image.cta'),
      onAddImage(selectedOption, uploadedFile, gallerySelectedFile) {
        if (lang === Lang.en) {
          setNewImageFile?.(
            selectedOption === ImageModalOptions.Gallery ? gallerySelectedFile : uploadedFile
          );
        } else {
          setNewFrenchImageFile?.(
            selectedOption === ImageModalOptions.Gallery ? gallerySelectedFile : uploadedFile
          );
        }
        setShowModal(false);
      },
    });
  };

  return (
    <>
      {[Lang.en, Lang.fr].map((lang) => {
        const languageFields = formItem[lang];

        const languageHandleClick = () => handleImageModalClick(languageFields.modalTitle, lang);

        return (
          (lang === Lang.en || displayFrenchFields) && (
            <div
              key={`${languageFields.label}-${lang}`}
              className={cx(styles.language, {
                [styles.languageEditing]: isEditing,
                [styles.languageImage]: formItem?.type === FieldTypes.Image,
              })}
            >
              {(isEditing || languageFields.value || formItem?.type === FieldTypes.Image) && (
                <Text type="body" color={Colors.CFDarkGrey} className={styles.readOnlyLinkText}>
                  {languageFields.label}
                </Text>
              )}
              {isEditing ? (
                <EditingValue
                  languageFields={languageFields}
                  type={formItem?.type ?? undefined}
                  handleClick={languageHandleClick}
                  lang={lang}
                  errorText={
                    formItem.type === FieldTypes.Image
                      ? t('error:food_provider_form.image.MISSING')
                      : 'INVALID_FORMAT'
                  }
                />
              ) : (
                <ReadOnlyValue
                  languageFields={languageFields}
                  setIsEditing={setIsEditing}
                  type={formItem?.type ?? undefined}
                  isLoading={isLoading}
                />
              )}
            </div>
          )
        );
      })}
    </>
  );
};

export default FoodProviderSingleFields;
