import { useTranslation } from 'hooks';
import { useFoodProviderContext } from 'context/food-provider-context';
import OrderOptionCard, {
  OrderOptionCardType,
} from 'components/order-option-card/order-option-card';
import { ReadOnlyButton } from '../food-provider-form';
import { orderServiceToNestedOrderService } from 'helpers/foodProvider';
import { OrderOption } from 'types/orderOption.type';

const OrderOptionCards = ({ textInputClassName }: { textInputClassName: string }) => {
  const { t } = useTranslation();

  const {
    selectedOrderOptions,
    setSelectedOrderOptions,
    orderOptionsToCreate,
    setOrderOptionsToCreate,
    setOrderOptionsToDelete,

    orderServiceOptions,

    isEditing,
    setIsEditing,

    incompleteOrderOptionCards,
    setIncompleteOrderOptionCards,
  } = useFoodProviderContext();

  const filterOutCurrentIndex = (items: any[], index: number) =>
    items.filter((_, filterIndex) => filterIndex !== index);
  const setOptions = (
    currentOrderOptions: Partial<OrderOption>[],
    index: number,
    updatedOrderOption: Partial<OrderOption>
  ) =>
    currentOrderOptions.map((originalOrderOption, originalIndex) =>
      originalIndex !== index ? originalOrderOption : updatedOrderOption
    );

  return (
    <>
      {selectedOrderOptions?.map((orderOption, index) => {
        const onDelete = () => {
          // Add to the to-be-deleted bucket
          setOrderOptionsToDelete((props) => [...props, orderOption]);

          // Remove from existing bucket
          setSelectedOrderOptions((props) => filterOutCurrentIndex(props ?? [], index));
        };

        const onSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
          const updatedOrderOption = orderOption;
          const option = orderServiceOptions?.find(
            (orderService) => orderService?.sys?.id === e.target.value
          );

          if (option) {
            updatedOrderOption.orderService = orderServiceToNestedOrderService(option);
            setSelectedOrderOptions((props) => [
              ...filterOutCurrentIndex(props ?? [], index),
              updatedOrderOption,
            ]);
          }
        };

        return (
          <OrderOptionCard
            setIsEditing={setIsEditing}
            isReadOnly={!isEditing}
            type={OrderOptionCardType.Existing}
            key={`order-option-${orderOption?.sys?.id ?? index}`}
            orderOption={orderOption}
            textInputClassName={textInputClassName}
            onDelete={onDelete}
            onSelect={onSelect}
          />
        );
      })}
      {isEditing &&
        orderOptionsToCreate?.map((orderOption, index) => {
          const onDelete = () =>
            setOrderOptionsToCreate((props) => filterOutCurrentIndex(props ?? [], index));
          const onSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
            const updatedOrderOption = orderOption;
            const option = orderServiceOptions?.find(
              (orderService) => orderService?.sys?.id === e.target.value
            );

            if (option) {
              updatedOrderOption.orderService = orderServiceToNestedOrderService(option);

              setOrderOptionsToCreate((props) => [
                ...setOptions(props ?? [], index, updatedOrderOption),
              ]);
            }
          };
          return (
            <OrderOptionCard
              type={OrderOptionCardType.New}
              key={`order-option-to-create-${orderOption?.sys?.id ?? index}`}
              orderOption={orderOption}
              textInputClassName={textInputClassName}
              onDelete={onDelete}
              onSelect={onSelect}
            />
          );
        })}
      {isEditing &&
        Array.from({ length: incompleteOrderOptionCards }).map((_, index) => {
          const onSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
            const option = orderServiceOptions?.find(
              (orderService) => orderService?.sys?.id === e.target.value
            );

            setOrderOptionsToCreate((props) => [
              ...props,
              {
                orderService: option ? orderServiceToNestedOrderService(option) : undefined,
              },
            ]);
            setIncompleteOrderOptionCards((props) => props - 1);
          };
          const onDelete = () => setIncompleteOrderOptionCards((props) => props - 1);
          return (
            <OrderOptionCard
              key={`unselected-order-option-${index}`}
              onSelect={onSelect}
              onDelete={onDelete}
            />
          );
        })}
      {!isEditing && selectedOrderOptions?.length <= 0 && orderOptionsToCreate?.length <= 0 && (
        <ReadOnlyButton
          title={t('food_provider_view.order_options.cta')}
          setIsEditing={setIsEditing}
        />
      )}
    </>
  );
};

export default OrderOptionCards;
