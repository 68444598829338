/* eslint-disable no-useless-computed-key */
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { EntryCollection, FoodProvider } from 'types';
import Lang from 'constants/Locale';
import {
  fetchFloors,
  fetchFoodProviders,
  fetchSelectedOrderOptions,
  filterSelectedOrderServices,
  fetchProperties,
  handleAvailableStores,
  fetchOrderServices,
  fetchOrderMethods,
  fetchUnits,
  handleSubmit,
  fetchFloorById,
  getFloorFieldId,
} from 'helpers/foodProvider';
import { useAuthContext } from 'context/auth-context';
import { useRequester } from './requesters';
import FoodProviderRequester from './requesters/foodProvider/foodProviderRequester';
import Property from 'types/property.type';
import Floor from 'types/floor.type';
import { LocalizedOrderService } from 'types/orderService.type';
import OrderMethod from 'types/orderMethod.type';
import { OrderOption } from 'types/orderOption.type';
import UnitType from 'types/unit.type';
import { useBreakpoints } from 'hooks';
import { handleFileUrl } from 'components/form/image-input';
import { DeepPartial } from 'types/global.type';
import hash from 'helpers/hash';

export enum AccordionInputType {
  RadioButtons = 'radio',
  Checkboxes = 'checkbox',
}

export enum AccordionType {
  Sort = 'sort',
  Filter = 'filter',
  State = 'state',
}

export interface SortAccordionOption {
  title: string;
  value: string;
}

export enum StateFilterOptions {
  All = 'all',
  Approved = 'approved',
  Pending = 'pending',
  Draft = 'draft',
  Declined = 'declined',
}

export enum SortOptions {
  RecentActivity = 'all',
  Alphabetical = 'asc',
  AlphabeticalReversed = 'desc',
}

type FoodProviderContextProps = {
  appliedState: StateFilterOptions;
  setAppliedState: React.Dispatch<React.SetStateAction<StateFilterOptions>>;

  appliedSort: SortOptions;
  setAppliedSort: React.Dispatch<React.SetStateAction<SortOptions>>;

  appliedProperties: string[];
  setAppliedProperties: React.Dispatch<React.SetStateAction<string[]>>;

  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;

  stateOptions: Record<StateFilterOptions, string> | null;
  sortOptions: Record<SortOptions, string> | null;

  propertyFilteringOptions: Record<string, string> | null;
  availablePropertyOptions: Record<string, string> | null;
  setAvailablePropertyOptions: React.Dispatch<React.SetStateAction<Record<string, string> | null>>;
  availableUnits: UnitType[];
  setAvailableUnits: React.Dispatch<React.SetStateAction<UnitType[]>>;

  orderServiceOptions: LocalizedOrderService[] | null;
  orderMethodOptions: OrderMethod[] | null;

  selectedFoodProvider: DeepPartial<FoodProvider> | null;
  setSelectedFoodProvider: React.Dispatch<React.SetStateAction<DeepPartial<FoodProvider> | null>>;

  selectedOrderServices: LocalizedOrderService[];
  setSelectedOrderServices: React.Dispatch<React.SetStateAction<LocalizedOrderService[]>>;

  selectedOrderOptions: OrderOption[];
  setSelectedOrderOptions: React.Dispatch<React.SetStateAction<OrderOption[]>>;

  orderOptionsToCreate: Partial<OrderOption>[];
  setOrderOptionsToCreate: React.Dispatch<React.SetStateAction<Partial<OrderOption>[]>>;

  orderOptionsToDelete: Partial<OrderOption>[];
  setOrderOptionsToDelete: React.Dispatch<React.SetStateAction<Partial<OrderOption>[]>>;

  foodProviders: EntryCollection<FoodProvider> | null;
  setFoodProviders: React.Dispatch<React.SetStateAction<EntryCollection<FoodProvider> | null>>;

  foodProviderToCreate: DeepPartial<FoodProvider> | null;
  setFoodProviderToCreate: React.Dispatch<React.SetStateAction<DeepPartial<FoodProvider> | null>>;

  displayFrenchFields: boolean;

  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;

  incompleteOrderOptionCards: number;
  setIncompleteOrderOptionCards: React.Dispatch<React.SetStateAction<number>>;

  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;

  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;

  foodProviderRequester?: FoodProviderRequester;

  floors: Floor[] | null;
  setFloors: React.Dispatch<React.SetStateAction<Floor[] | null>>;

  selectedFloor: Floor | null;
  setSelectedFloor: React.Dispatch<React.SetStateAction<Floor | null>>;

  fetchFoodProviders?: () => void;
  fetchProperties?: () => void;
  fetchUnits?: () => void;
  handleAvailableStores?: () => void;
  handleSubmit?: (saveAsDraft: boolean) => void | Promise<{ id: string } | null | undefined>;

  newImageFile?: File | null;
  setNewImageFile?: React.Dispatch<React.SetStateAction<File | null | undefined>>;

  newFrenchImageFile?: File | null;
  setNewFrenchImageFile?: React.Dispatch<React.SetStateAction<File | null | undefined>>;

  newImageUrl?: string | null;
  setNewImageUrl?: React.Dispatch<React.SetStateAction<string | null>>;

  newFrenchImageUrl?: string | null;
  setNewFrenchImageUrl?: React.Dispatch<React.SetStateAction<string | null>>;
};

const FoodProviderContext = createContext<FoodProviderContextProps>({
  appliedState: StateFilterOptions.All,
  setAppliedState: () => {},

  appliedSort: SortOptions.RecentActivity,
  setAppliedSort: () => {},

  appliedProperties: ['all'],
  setAppliedProperties: () => {},

  searchValue: '',
  setSearchValue: () => {},

  stateOptions: null,
  sortOptions: null,

  propertyFilteringOptions: null,
  availablePropertyOptions: null,
  setAvailablePropertyOptions: () => {},
  availableUnits: [],
  setAvailableUnits: () => {},

  orderServiceOptions: null,
  orderMethodOptions: null,

  selectedFoodProvider: null,
  setSelectedFoodProvider: () => {},

  selectedOrderServices: [],
  setSelectedOrderServices: () => {},

  selectedOrderOptions: [],
  setSelectedOrderOptions: () => {},

  orderOptionsToCreate: [],
  setOrderOptionsToCreate: () => {},

  orderOptionsToDelete: [],
  setOrderOptionsToDelete: () => {},

  foodProviders: null,
  setFoodProviders: () => {},
  foodProviderToCreate: null,
  setFoodProviderToCreate: () => {},

  displayFrenchFields: false,

  isEditing: false,
  setIsEditing: () => {},

  incompleteOrderOptionCards: 0,
  setIncompleteOrderOptionCards: () => {},

  currentPage: 1,
  setCurrentPage: () => {},

  isLoading: true,
  setIsLoading: () => {},

  foodProviderRequester: undefined,

  floors: null,
  setFloors: () => {},

  selectedFloor: null,
  setSelectedFloor: () => {},

  fetchFoodProviders: () => {},
  handleSubmit: (saveAsDraft = false) => {},

  newImageFile: null,
  setNewImageFile: () => {},

  newFrenchImageFile: null,
  setNewFrenchImageFile: () => {},

  newImageUrl: null,
  setNewImageUrl: () => {},

  newFrenchImageUrl: null,
  setNewFrenchImageUrl: () => {},
});

export type PersistentStateRef = null | {
  floorFieldId?: string | null;
  orderOptionIdsHash?: string | null;
};

export function FoodProviderContextProvider({ children }: Readonly<{ children: ReactNode }>) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const foodProviderRequester = useRequester<FoodProviderRequester>();

  const { isUserMgmt } = useAuthContext();

  // Sorting and filtering
  const [appliedState, setAppliedState] = useState<StateFilterOptions>(StateFilterOptions.All);
  const [appliedSort, setAppliedSort] = useState<SortOptions>(SortOptions.RecentActivity);
  const [appliedProperties, setAppliedProperties] = useState<string[]>(['all']);

  // Possible options
  const [stateOptions, setStateOptions] = useState<Record<StateFilterOptions, string> | null>(null);
  const [sortOptions, setSortOptions] = useState<Record<SortOptions, string> | null>(null);

  const [propertyFilteringOptions, setPropertyFilteringOptions] = useState<Record<
    string,
    string
  > | null>(null);
  const [availablePropertyOptions, setAvailablePropertyOptions] = useState<Record<
    string,
    string
  > | null>(null);
  const [availableUnits, setAvailableUnits] = useState<UnitType[]>([]);

  const [allProperties, setAllProperties] = useState<Property[] | null>(null);
  const [allUnits, setAllUnits] = useState<UnitType[] | null>(null);

  const [orderServiceOptions, setOrderServiceOptions] = useState<LocalizedOrderService[] | null>(
    null
  );
  const [orderMethodOptions, setOrderMethodOptions] = useState<OrderMethod[] | null>(null);

  // Selected values
  const [selectedFoodProvider, setSelectedFoodProvider] =
    useState<DeepPartial<FoodProvider> | null>(null); // Could be existing or a new one
  const [selectedOrderServices, setSelectedOrderServices] = useState<LocalizedOrderService[]>([]);
  const [selectedFloor, setSelectedFloor] = useState<Floor | null>(null);

  // Order Options: 3 buckets (existing, to create & to delete)
  const [selectedOrderOptions, setSelectedOrderOptions] = useState<OrderOption[]>([]);
  const [orderOptionsToCreate, setOrderOptionsToCreate] = useState<Partial<OrderOption>[]>([]);
  const [orderOptionsToDelete, setOrderOptionsToDelete] = useState<Partial<OrderOption>[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);

  // Food providers: 2 buckets (existing & to create)
  const [foodProviders, setFoodProviders] = useState<EntryCollection<FoodProvider> | null>(null);
  const [floors, setFloors] = useState<Floor[] | null>(null);
  const [foodProviderToCreate, setFoodProviderToCreate] =
    useState<DeepPartial<FoodProvider> | null>(null);

  const [searchValue, setSearchValue] = useState<string>('');
  const [displayFrenchFields, setDisplayFrenchFields] = useState<boolean>(false);

  const [newImageFile, setNewImageFile] = useState<File | null | undefined>(null);
  const [newFrenchImageFile, setNewFrenchImageFile] = useState<File | null | undefined>(null);

  const [newImageUrl, setNewImageUrl] = useState<string | null>(null);
  const [newFrenchImageUrl, setNewFrenchImageUrl] = useState<string | null>(null);

  const persistentStateRef = useRef<PersistentStateRef>(null);
  const currentProviderIdRef = useRef<string | null | undefined>(null);

  useEffect(() => {
    if (!foodProviders && !isLoading) {
      setIsLoading(true);
    }
  }, [foodProviders]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchValue, appliedState, appliedProperties]);

  // Initial fetch of generic information
  // If a user has isUserMgmt role, do not fetch food provider API as it'll throw 401
  useEffect(() => {
    if (foodProviderRequester && !isUserMgmt) {
      fetchProperties(foodProviderRequester, setAllProperties, setIsLoading);
      fetchUnits(foodProviderRequester, setAllUnits, setIsLoading);
      fetchOrderServices(foodProviderRequester, setOrderServiceOptions, setIsLoading);
      fetchOrderMethods(foodProviderRequester, setOrderMethodOptions, setIsLoading);
    } else {
      setIsLoading(true);
    }
  }, [foodProviderRequester]);

  // Fetch food providers for non user management roles
  useEffect(() => {
    if (!isUserMgmt) {
      fetchFoodProviders({
        requester: foodProviderRequester,
        appliedState,
        currentPage,
        searchValue,
        appliedSort,
        appliedProperties,
        setFoodProviders,
        setSelectedFoodProvider,
        setIsLoading,
        isMobile,
      });
    }
  }, [
    foodProviderRequester,
    appliedState,
    appliedSort,
    appliedProperties,
    currentPage,
    searchValue,
  ]);

  useEffect(() => {
    if (currentProviderIdRef.current !== selectedFoodProvider?.sys?.id) {
      setOrderOptionsToCreate([]);
      setOrderOptionsToDelete([]);

      setIncompleteOrderOptionCards(initialCardNumber);
    }
  }, [selectedFoodProvider, persistentStateRef]);

  useEffect(() => {
    if (foodProviderRequester) {
      if (selectedFoodProvider?.sys?.id) {
        currentProviderIdRef.current = selectedFoodProvider?.sys?.id;
        if (foodProviderToCreate) {
          setFoodProviderToCreate(null); // Removes the unsaved draft if user clicks on another food provider
        }
      } else {
        currentProviderIdRef.current = undefined;
        setFoodProviderToCreate(selectedFoodProvider);
      }

      const orderOptionIds =
        (selectedFoodProvider?.orderOptions?.filter(
          (orderOption) => orderOption !== undefined
        ) as string[]) ?? [];

      // Set the order options for said food provider
      if (hash(orderOptionIds) !== persistentStateRef?.current?.orderOptionIdsHash) {
        fetchSelectedOrderOptions(
          foodProviderRequester,
          setSelectedOrderOptions,
          persistentStateRef,
          orderOptionIds,
          setIsLoading
        );
      }

      if (getFloorFieldId(selectedFoodProvider) !== persistentStateRef?.current?.floorFieldId) {
        fetchFloorById(
          foodProviderRequester,
          selectedFoodProvider,
          setSelectedFloor,
          persistentStateRef,
          setIsLoading
        );
      }

      const propertyId = selectedFoodProvider?.property?.[Lang.en]?.sys?.id;

      const propertyEntry = propertyId
        ? allProperties?.find((property) => property?.sys?.id === propertyId)
        : null;

      setDisplayFrenchFields(propertyEntry?.requiresFrench ?? false);
    } else {
      setIsLoading(true);
    }
  }, [selectedFoodProvider, persistentStateRef]);

  useEffect(() => {
    filterSelectedOrderServices(
      orderServiceOptions,
      selectedOrderOptions,
      setSelectedOrderServices,
      setIsLoading
    );
  }, [selectedOrderOptions, orderServiceOptions]);

  useEffect(() => {
    if (!foodProviderToCreate) {
      // Auto-selects the first food provider once the unsaved draft disappears
      setSelectedFoodProvider(foodProviders?.items?.[0] ?? null);
    }
  }, [foodProviderToCreate]);

  useEffect(() => {
    if (t) {
      setIsLoading(true);

      setStateOptions({
        [StateFilterOptions.All]: t('food_provider_left_panel.filters.all'),
        [StateFilterOptions.Approved]: t('approved'),
        [StateFilterOptions.Declined]: t('declined'),
        [StateFilterOptions.Pending]: t('pending'),
        [StateFilterOptions.Draft]: t('draft'),
      });

      setSortOptions({
        [SortOptions.RecentActivity]: t('food_provider_left_panel.filters.recent_activity'),
        [SortOptions.Alphabetical]: t('food_provider_left_panel.filters.alphabetical'),
        [SortOptions.AlphabeticalReversed]: t(
          'food_provider_left_panel.filters.alphabetical_reversed'
        ),
      });

      setIsEditing(false);

      setIsLoading(false);
    }
  }, [t]);

  useEffect(() => {
    if (t && allProperties) {
      handleAvailableStores({
        requester: foodProviderRequester,
        t,
        foodProviders,
        propertyList: allProperties,
        unitList: allUnits,
        setAvailablePropertyOptions,
        setAvailableUnits,
        setPropertyFilteringOptions,
        setIsLoading,
      });
    }
  }, [t, foodProviderRequester, allProperties, allUnits]);

  useEffect(() => {
    handleAvailableStores({
      requester: foodProviderRequester,
      t,
      foodProviders,
      propertyList: allProperties,
      unitList: allUnits,
      setAvailablePropertyOptions,
      setAvailableUnits,
      setPropertyFilteringOptions,
      setIsLoading,
    });

    setIsEditing(false);

    fetchFloors(foodProviderRequester, foodProviders, setFloors, setIsLoading);
  }, [foodProviders, foodProviderRequester]);

  useEffect(() => {
    handleFileUrl(setNewFrenchImageUrl, newFrenchImageFile);
  }, [newFrenchImageFile]);

  useEffect(() => {
    if (newFrenchImageUrl) {
      const imageUrl = selectedFoodProvider?.imageUrl;
      setSelectedFoodProvider((props) => ({
        ...props,
        imageUrl: {
          ...imageUrl,
          [Lang.fr]: newFrenchImageUrl,
        },
      }));
    }
  }, [newFrenchImageUrl]);

  useEffect(() => {
    handleFileUrl(setNewImageUrl, newImageFile);
  }, [newImageFile]);

  useEffect(() => {
    if (newImageUrl) {
      const imageUrl = selectedFoodProvider?.imageUrl;
      setSelectedFoodProvider((props) => ({
        ...props,
        imageUrl: {
          ...imageUrl,
          [Lang.en]: newImageUrl,
        },
      }));
    }
  }, [newImageUrl]);

  // Update context utility values when they change
  useEffect(() => {
    contextUtil.isEditing = isEditing;
  }, [isEditing]);

  const initialCardNumber =
    selectedFoodProvider?.orderOptions && selectedFoodProvider?.orderOptions.length > 0 ? 0 : 1;

  const [incompleteOrderOptionCards, setIncompleteOrderOptionCards] =
    useState<number>(initialCardNumber);

  const sharedState: FoodProviderContextProps = useMemo(
    () => ({
      isLoading,
      setIsLoading,

      isEditing,
      setIsEditing,

      foodProviderRequester,

      appliedState,
      setAppliedState,

      appliedSort,
      setAppliedSort,

      appliedProperties,
      setAppliedProperties,

      searchValue,
      setSearchValue,

      stateOptions,
      sortOptions,

      propertyFilteringOptions,
      availablePropertyOptions,
      setAvailablePropertyOptions,
      availableUnits,
      setAvailableUnits,

      orderServiceOptions,
      orderMethodOptions,

      foodProviders,
      setFoodProviders,
      foodProviderToCreate,
      setFoodProviderToCreate,

      selectedFoodProvider,
      setSelectedFoodProvider,

      selectedOrderServices,
      setSelectedOrderServices,

      selectedOrderOptions,
      setSelectedOrderOptions,

      orderOptionsToCreate,
      setOrderOptionsToCreate,

      orderOptionsToDelete,
      setOrderOptionsToDelete,

      displayFrenchFields,

      incompleteOrderOptionCards,
      setIncompleteOrderOptionCards,

      currentPage,
      setCurrentPage,

      floors,
      setFloors,
      selectedFloor,
      setSelectedFloor,

      fetchFoodProviders: () =>
        fetchFoodProviders({
          requester: foodProviderRequester,
          appliedState,
          currentPage,
          searchValue,
          appliedSort,
          appliedProperties,
          setFoodProviders,
          setSelectedFoodProvider,
          setIsLoading,
          isMobile,
        }),

      handleSubmit: async (saveAsDraft = false) => {
        currentProviderIdRef.current = null;
        const res = handleSubmit({
          requester: foodProviderRequester,
          selectedFoodProvider,
          orderOptionsToCreate,
          orderOptionsToDelete,
          selectedOrderOptions,
          fileEnglish: newImageFile,
          fileFrench: newFrenchImageFile,
          saveAsDraft,
        });
        return res;
      },

      fetchProperties: async () => {
        fetchProperties(foodProviderRequester, setAllProperties, setIsLoading);
      },

      fetchUnits: async () => {
        fetchUnits(foodProviderRequester, setAllUnits, setIsLoading);
      },

      handleAvailableStores: async () => {
        handleAvailableStores({
          requester: foodProviderRequester,
          t,
          foodProviders,
          propertyList: allProperties,
          unitList: allUnits,
          setAvailablePropertyOptions,
          setAvailableUnits,
          setPropertyFilteringOptions,
          setIsLoading,
        });
      },

      newImageFile,
      setNewImageFile,

      newFrenchImageFile,
      setNewFrenchImageFile,

      newImageUrl,
      setNewImageUrl,

      newFrenchImageUrl,
      setNewFrenchImageUrl,
    }),
    [
      isLoading,
      isEditing,

      foodProviderRequester,

      appliedState,
      appliedSort,
      appliedProperties,
      searchValue,

      stateOptions,
      sortOptions,

      propertyFilteringOptions,
      availablePropertyOptions,
      availableUnits,

      selectedFoodProvider,
      foodProviderToCreate,

      selectedOrderOptions,
      orderOptionsToCreate,
      orderOptionsToDelete,

      incompleteOrderOptionCards,

      currentPage,

      floors,
      selectedFloor,

      newImageFile,
      newFrenchImageFile,

      newImageUrl,
      newFrenchImageUrl,
    ]
  );

  return (
    <FoodProviderContext.Provider value={sharedState}>{children}</FoodProviderContext.Provider>
  );
}

/*
  Utility variable for getting context values outside of components
  To add another property, update the contextUtil useEffect as well
*/
const contextUtil: Partial<FoodProviderContextProps> = {
  isEditing: false,
};

export function getContextUtil(prop: keyof FoodProviderContextProps) {
  return contextUtil[prop];
}

export function useFoodProviderContext() {
  const context = useContext(FoodProviderContext);
  if (!context) {
    throw new Error('useFoodProviderContext must be used within a FoodProviderProvider');
  }
  return context;
}
