const IconUrlMapping = {
  add: '/icons/add.svg',
  alert: '/icons/alert.svg',
  archiveRed: '/icons/trash-red.svg',
  back: '/icons/chevron-left.svg',
  caret: '/icons/caret.svg',
  caretTriangle: '/icons/caret-triangle.svg',
  caretBlue: '/icons/caret-blue.svg',
  caretBlueUp: '/icons/caret-blue-up.svg',
  checked: '/icons/submit.svg',
  checkedWhite: '/icons/submit-white.svg',
  checkMark: '/icons/check.svg',
  chevronUp: '/icons/chevron-up.svg',
  chevronDown: '/icons/chevron-down.svg',
  chevronLeftBlue: '/icons/chevron-left-blue.svg',
  close: '/icons/close.svg',
  closeOutlined: '/icons/close-outlined.svg',
  closeBlue: '/icons/close-blue.svg',
  discard: '/icons/trash.svg',
  duplicate: '/icons/duplicate.svg',
  edit: '/icons/edit.svg',
  ellipse: '/icons/ellipseGray.svg',
  ellipseBlue: '/icons/ellipseBlue.svg',
  eye: '/icons/eye.svg',
  eyeCrossed: '/icons/eye-crossed.svg',
  filter: '/icons/filter.svg',
  hamburger: '/icons/hamburger.svg',
  language: '/icons/language.svg',
  save: '/icons/save.svg',
  search: '/icons/search.svg',
  searchBlack: '/icons/search-black.svg',
  meatBall: '/icons/more.svg',
  plus: '/icons/iconPlus.svg',
  plusBlue: '/icons/iconPlusBlue.svg',
  question: '/icons/tooltip.svg',
  tapestry: '/icons/tapestry.svg',
  tapestryTop: '/icons/tapestry-top.svg',
  trash: '/icons/trash.svg',
  trashRed: '/icons/trash-red.svg',
  checkboxEmpty: '/icons/checkbox-empty.svg',
  checkboxFilled: '/icons/checkbox-filled.svg',
  arrowLeft: '/icons/arrow-left.svg',
  tapestryTopLeft: '/icons/tapestry-top-left.svg',
  circularArrow: '/icons/circular-arrow.svg',
  circularArrowFull: '/icons/circular-arrow-full.svg',
  exclamation: '/icons/exclamation.svg',
};

export default IconUrlMapping;
