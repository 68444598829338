const LimitsValidations = {
  atLeast: (limit: number) => ({
    size: {
      min: limit,
      max: null,
    },
  }),
  between: (least: number, most: number) => {
    let range = `${least} - ${most}`;
    if (least === most) {
      range = String(least);
    }
    return {
      size: {
        min: least,
        max: most,
      },
      message: `This field requires ${range} items.`,
    };
  },
  characterLimit: (limit: number) => ({
    size: {
      min: null,
      max: limit,
    },
  }),
  range: (least: number, most: number) => {
    let range = `${least} - ${most}`;
    if (least === most) {
      range = String(least);
    }
    return {
      range: {
        min: least,
        max: most,
      },
      message: `This field Requires ${range} items.`,
    };
  },
};

export default LimitsValidations;
