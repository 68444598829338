import cx from 'classnames';

interface AccessibilityLiveProps {
  isOn: boolean;
  className?: string;
  label: string;
}

const AccessibilityLive = ({ isOn, label, className }: AccessibilityLiveProps) => (
  <div className={cx('sr-only', className)} aria-atomic aria-live={isOn ? 'polite' : 'off'}>
    {label}
  </div>
);

export default AccessibilityLive;
