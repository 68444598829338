import { useMemo } from 'react';
import { Button, CustomButton, Shimmer, Text } from 'components';
import styles from './header.module.scss';
import { useBreakpoints, useTranslation } from 'hooks';
import { Colors } from 'types/color.type';
import { useFoodProviderContext } from 'context/food-provider-context';
import { useAppContext } from 'context/app-context';
import Status from 'constants/Status';
import Lang from 'constants/Locale';
import { getIconDimensions } from 'helpers/foodProvider';

interface FoodProviderFormHeaderProps {
  handleSubmitModal: () => void;
  handleDeleteModal: () => void;
  handleSaveDraft: () => void;
}

const FoodProviderFormHeader = ({
  handleSubmitModal,
  handleDeleteModal,
  handleSaveDraft,
}: FoodProviderFormHeaderProps) => {
  const { t } = useTranslation();
  const { locale } = useAppContext();
  const { isMobile } = useBreakpoints();

  const {
    selectedFoodProvider,

    isEditing,
    setIsEditing,

    isLoading,

    selectedFloor,
  } = useFoodProviderContext();

  const dimensions = useMemo(() => getIconDimensions(isMobile), [isMobile]);

  const currentPropertyName =
    selectedFoodProvider?.property?.[Lang.en]?.fields?.WebPropertyName?.[Lang.en];
  const currentFloorName = selectedFloor?.name?.[Lang.en];

  return (
    <div className={styles.header}>
      <div>
        {isLoading ? (
          <Shimmer height={isMobile ? 51 : 74} />
        ) : (
          <>
            <Text
              className={styles.unitTitle}
              tag="h3"
              type="h3"
              color={Colors.MidnightBlue}
              highlightColor={Colors.SkyBlue}
            >
              {selectedFoodProvider?.unit?.[Lang.en]?.fields?.WebStoreName?.[locale] ??
                t('food_provider_view.store_information')}
            </Text>

            <Text className={styles.unitSubtitle} type="h5" color={Colors.MidnightBlue}>
              {currentPropertyName && currentFloorName
                ? `${currentPropertyName} | ${currentFloorName}`
                : t('food_provider_view.property_details')}
            </Text>
          </>
        )}
      </div>
      <div className={styles.headerWrapper}>
        <Button
          disabled={isLoading}
          className={styles.headerButton}
          onClick={() => {
            if (!isEditing) {
              setIsEditing(true);
            } else {
              handleSubmitModal();
            }
          }}
        >
          {t(isEditing ? 'food_provider_edit.submit' : 'edit')}
        </Button>
        {isEditing && (selectedFoodProvider?.status as Status) !== Status.Published && (
          <CustomButton
            disabled={
              isLoading ||
              !selectedFoodProvider?.property?.[Lang.en]?.sys?.id ||
              !selectedFoodProvider?.unit?.[Lang.en]?.sys?.id
            }
            label={t('food_provider_edit.save_draft')}
            variant="cf-text"
            alt=""
            text=""
            iconName="save"
            iconHeight={dimensions.save.height}
            iconWidth={dimensions.save.width}
            className={styles.headerButtonDraft}
            onClick={handleSaveDraft}
          />
        )}
        <CustomButton
          disabled={isLoading}
          label={t('delete_food_provider_modal.delete')}
          variant="cf-text"
          alt=""
          text=""
          iconName="trash"
          iconHeight={dimensions.trash.height}
          iconWidth={dimensions.trash.height}
          className={styles.headerButtonDelete}
          onClick={() => handleDeleteModal()}
        />
      </div>
    </div>
  );
};

export default FoodProviderFormHeader;
