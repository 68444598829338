import { Route, useHistory, Redirect, useLocation } from 'react-router-dom';
import Paths from 'constants/Paths';
import { oktaAuthConfig, oktaSignInConfig } from 'config/okta';
import { Security, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { ModalContextProvider } from 'context/modal-context';
import { AuthContextProvider } from 'context/auth-context';
import {
  LoginPage,
  BrowseOffersPage,
  CreateOfferPage,
  UserManagementPage,
  SignupPage,
  AdminProfile,
  ForgotPasswordPage,
  SetPasswordPage,
  FoodProvidersPage,
} from 'pages';
import { useAuthContext } from 'context/auth-context';
import { SetPasswordPageState } from 'pages/SetPasswordPage/SetPasswordPage';
import { RequesterProvider, RequesterTypes } from 'context/requesters';
import { FoodProviderContextProvider } from 'context/food-provider-context';

const oktaAuth = new OktaAuth(oktaAuthConfig);

const HomePageRedirect = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated, hasAuthState, homeRedirect } = useAuthContext();
  if (!hasAuthState) return null;
  if (isAuthenticated) {
    return <Redirect to={{ pathname: homeRedirect }} />;
  } else {
    return <>{children}</>;
  }
};

const UserMgmtRoute = ({ children }: { children: React.ReactNode }) => {
  const { isUserMgmt, hasAuthState } = useAuthContext();
  if (!hasAuthState) return null;
  return isUserMgmt ? <>{children}</> : <Redirect to="/" />;
};

const NonUserMgmtRoute = ({ children }: { children: React.ReactNode }) => {
  const { isUserMgmt, hasAuthState } = useAuthContext();
  if (!hasAuthState) return null;
  return !isUserMgmt ? <>{children}</> : <Redirect to="/" />;
};

function AppRouter() {
  const location = useLocation();
  const history = useHistory();

  const customAuthHandler = () => {
    const params = new URLSearchParams(location.search);
    const lang = params.get('lang');
    const langQuery = lang ? `?lang=${lang}` : '';
    history.push(`${Paths.loginPage}${langQuery}`);
  };

  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <AuthContextProvider>
        <RequesterProvider type={RequesterTypes.FoodProvider}>
          <FoodProviderContextProvider>
            <ModalContextProvider>
              <Route exact path={Paths.loginPage}>
                <HomePageRedirect>
                  <LoginPage config={oktaSignInConfig} />
                </HomePageRedirect>
              </Route>

              <Route exact path={Paths.forgotPassword}>
                <ForgotPasswordPage />
              </Route>

              <Route path={Paths.resetPassword}>
                <SetPasswordPage page={SetPasswordPageState.reset} />
              </Route>

              <Route path={Paths.activate}>
                <SetPasswordPage page={SetPasswordPageState.activate} />
              </Route>

              <SecureRoute exact path={Paths.foodProvidersPage}>
                <NonUserMgmtRoute>
                  <FoodProvidersPage />
                </NonUserMgmtRoute>
              </SecureRoute>

              <RequesterProvider type={RequesterTypes.Offer}>
                <SecureRoute exact path={Paths.createOfferPage}>
                  <NonUserMgmtRoute>
                    <CreateOfferPage />
                  </NonUserMgmtRoute>
                </SecureRoute>

                <SecureRoute exact path={Paths.browseOffersPage}>
                  <NonUserMgmtRoute>
                    <BrowseOffersPage />
                  </NonUserMgmtRoute>
                </SecureRoute>
              </RequesterProvider>

              <SecureRoute exact path={Paths.profile}>
                <AdminProfile />
              </SecureRoute>

              <SecureRoute path={Paths.userManagement}>
                <UserMgmtRoute>
                  <UserManagementPage />
                </UserMgmtRoute>
              </SecureRoute>

              <Route path={Paths.signupPage}>
                <SignupPage />
              </Route>

              {/* TODO: should have a catch all to 404 */}
            </ModalContextProvider>
          </FoodProviderContextProvider>
        </RequesterProvider>
      </AuthContextProvider>
    </Security>
  );
}

export default AppRouter;
